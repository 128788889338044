import React, { Component } from "react";
import { connect } from 'react-redux';
import { getReceivingReports } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { dateFormatMy, returnLabelWithValue } from 'helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import Pagination from 'components/ThemeComponents/Pagination';
import Select from 'react-select';
import { Link } from 'react-router-dom';
const moment = window.moment;

class ReceiveReports extends Component {
	constructor(props) {
		super(props);
		this.validator = new ReactValidator();
		this.state = {

			numOfDays: 30,
			collapseUiSettings: true,
		}
		this.getOrderMissingAgencyResourceCallWithNumDays = this.getOrderMissingAgencyResourceCallWithNumDays.bind(this);
		this.getRowProps = this.getRowProps.bind(this);
	}
	getOrderMissingAgencyResourceCallWithNumDays(data) {
		data = { ...data, numOfDays: this.state.numOfDays };
		return this.props.getReceivingReportsCall(data);
	}
	getRowProps(rowData) {
		let data = {};
		if (parseInt(rowData.settings.finalize_by_editor.value, 10) === 1) {
			data = { color: 'red' }
		}
		if (rowData.order_dates.receive_date.value === "" && rowData.shippingItemsCase > 0) {
			data = { color: 'green' }
		}
		if (rowData.no_equipment_upload_only === 1) {
			data = { color: 'pink' }
		}
		if ((rowData.order_dates.receive_date.value !== "") && (parseInt(rowData.settings.finalize_by_editor.value, 10) === 0) && (parseInt(rowData.settings.on_hold.value, 10) === 1)) {
			if (moment(rowData.settings.photos_submitted_for_video_production.updated_at) < moment(rowData.order_dates.upload_cut_off_date.value)) {
				data = { color: 'blue' }

			}
		}
		return data;
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevState.numOfDays !== this.state.numOfDays) {
			this.pagination.dataCall();
		}
	}
	handleChangeContactLogDays = (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			this.setState({ numOfDays: selectedOptionType['value'] });
		}
	}
	capitalizeFirstLetterOfEachWord = (str) => {
		return str.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
	}
	aiStatus = (record) => {
		let lbl = '';
		let status = '';
		// let result = '';
		if ( record && record.ai_status ) {
			lbl = record.ai_status[0].status;
			lbl = this.capitalizeFirstLetterOfEachWord(lbl)
			status = lbl;
			if ( lbl === 'Completed' || lbl === 'Failed' ) {//in progress
				status = lbl  + ' at ' + record.ai_status[0].ai_process_date + '';
			} else if ( lbl === 'In Progress' ) {//in progress
				let time = Math.floor(Number(record.ai_remaining_time) - Number(record.original_images_count * 7.5));
				status = Math.floor(time / 60);
				// result = this.formatMinutesToTimeUnits(status);
				status = lbl + ' ' + ' ' + status + ' Min(s) Remaining';
			}
		}
		return status;
	}
	render() {
		//const { collapseUiSettings, filtered, numOfDays } = this.state;
		const { collapseUiSettings, numOfDays } = this.state;
		const columns = [
			{
				Header: "Report Testing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Barcode",
						accessor: "barcode",
						className: "action-center",
						Cell: ({ value, original }) => {
							return <Link to={'/Admin/' + (original.reorder ? original.reorder : original.id) + '/lab-production-report'} target="_blank" className="blue_link">{value}</Link>
						},
                        width: 200,
					},

					{
						Header: "Group Name",
						accessor: "group_name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Departure Date",
						accessor: "order_dates.departure_date",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value.value)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Submission Date",
						accessor: "settings.photos_submitted_for_video_production",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value.updated_at)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Video Special Need Date",
						accessor: "order_dates.video_special_need_date",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value.value)}
							</div>
						),
						className: "action-center redText",
						sortable: false,
					},

					{
						Header: "Upload Cut off date",
						accessor: "order_dates.upload_cut_off_date",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value.value)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Ai Job Status",
						accessor: "group_name",
						className: "action-center",
						sortable: false,
						height: 100,
						Cell: ({ value, original }) => (
							<div><small>
								{
									this.aiStatus(original)
								}</small>
							</div>
						)
					},
					// {
					// 	Header: "Run Rename",
					// 	accessor: "rename",
					// 	className: "action-center",
					// 	sortable: false,
					// },
					{
						Header: "Number of Photos",
						accessor: "originalImages",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Submitted By",
						accessor: "settings.photos_submitted_for_video_production.updated_by_user",
						className: "action-center",
						sortable: false,
                        Cell: ({ original, value }) => (
                            console.log("original", value),
							<div>
								{
                                    value.id === 91
                                        ?
                                        <span>System</span>
                                         :
                                          value.roles[0].label === "Photo Admin"
                                           ?
                                           <span>PA</span>
                                            :
                                             <span>GTV rep</span>

                                }
							</div>
						),
					},
					// {
					// 	Header: "Sript Run Date",
					// 	accessor: "date",
					// 	className: "action-center",
					// 	sortable: false,
					// },
					// {
					// 	Header: "Program Type",
					// 	accessor: "programtype.title",
					// 	className: "action-center",
					// 	sortable: false,
					// },
				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
                                                <Col md={12}>
                                                    <div className="secHeading">
                                                        Receiving Dashboard
                                                    </div>
                                                    <div style={{ 'marginLeft': '15px' }}>
                                                        <Link to='/Admin/receive' className='btn-primary btn btn-default'>Receiving Barcode</Link>
                                                        <Link to='/Admin/recentlyReceiveOrders' className='btn-primary btn btn-default ml-md' target="_blank">Recently Received Order List</Link>
                                                        <Link to='/Admin/to_labels' className='btn-primary btn btn-default ml-md' target="_blank">Print Order Label</Link>
                                                        {/* <Link to='/Admin/recently-processed-orders' className='backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default'>Recently Processed Order List</Link> */}
                                                    </div>
												</Col>
												{/* <Col md={12}>
													<Card bsClass={["innerUiSettingsCard"]} title="Receiving Dashboard" collapseTrigger={collapseUiSettings} collapsHandler={() => this.setState({ collapseUiSettings: !this.state.collapseUiSettings })} collapsable={true}
														content={
															<div className="clearfix">
																<div style={{ 'marginLeft': '15px' }}>
																	<Link to='/Admin/receive' className='btn-primary btn btn-default'>Receiving Barcode</Link>
																</div>
																<Col md={12} className="mt-md">
																	<ul >
																		<li >If line is in RED, the rename and copy script has not been ran.</li>
																		<li >If line is in DARK GREEN, this is an Upload Only OR SD Gift Card and NOT Received.</li>
																		<li >If line is in PINK, EQUIPMENT Not Returning-Upload Only.</li>
																		<li >If line is in LIGHT BLUE, Order Received, not edited, is on HOLD and Submission Date was befor UCD.</li>
																	</ul>
																</Col>
															</div>
														}
													/>
												</Col> */}
												{/* <div className="col-md-4 col-md-offset-4  mt-md">
													<Select
														value={returnLabelWithValue(numOfDays, OC.UPLOADPHOTOSFORVIDEOPRORECEIVEDAYS)}
														onChange={this.handleChangeContactLogDays}
														options={OC.UPLOADPHOTOSFORVIDEOPRORECEIVEDAYS}
														isSearchable={true}
														isMulti={false}
														name='numOfDays'
														placeholder={'Select Days'}
													/>
												</div> */}
												<Col md={12} className="mt-md">
													<Pagination
														ref={(p) => this.pagination = p}
														columns={columns}
														getDataCall={this.getOrderMissingAgencyResourceCallWithNumDays}
														filterView={false}
														getRowProps={this.getRowProps}
														pageSize={100}
													/>
												</Col>

											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReceivingReportsCall: (data) => { return dispatch(getReceivingReports(data)) },
	});
};

function mapStateToProps(state) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveReports);
