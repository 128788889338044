import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  ButtonToolbar,
  Popover,
  Button
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { hasPermission } from 'helper/hasPermission';
import {
  getAgenciesPagination,
  changeAgencyStatus,
  deleteAgency,
  saveAgencySearchParams,
  getAllAgencies
} from 'redux/actions/agency-actions';
import {
  getAgencyType,
  getMainProgramType,
  getSalesRep,
  getAgencyUserPreferences,
  storeOrUpdateAgencyUserPreferences,
  getAgencyDefaultPreference
} from 'redux/actions/settings-actions';
import { Link } from 'react-router-dom';
import { getBrands } from "redux/actions/account-action";
// import ToggleButton from 'react-toggle-button';
import Select from 'react-select';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { themeCSSConstants } from 'redux/constant/themeCSS';
import { adminLabels } from 'redux/constant/admin-label-constant';
import Pagination from 'components/ThemeComponents/Pagination';
import { agenciesService } from 'services/agency';
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { selectChangeHandler, updateStateVariableDynamic, returnColumnValueWithColumnMatch, intersectionOfObjectsWithNewValue, returnAgencyQuickURL } from 'helper/helperFunctions';
import AgencyAdvanceSearchForm from '../Order/AgencyAdvanceSearchForm';
import AgencyUserPreferences from '../Order/AgencyUserPreferences';
import {objectsConstants as OC} from 'redux/constant/objects-constant';
var Modal = require('react-bootstrap-modal');
var cloneDeep = require('lodash.clonedeep');
// import { CSVLink } from "react-csv";
//var moment = require("moment");
const setAllTo = (val, object) => Object.keys(object).reduce((prev, curr) => ({
    ...prev,
    [curr]: typeof object[curr] === 'object' ?
        setAllTo(val, object[curr]) : val
}), {});
class AgencyListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionsCompanyType: [],
            optionsProgramType: [],
            selectedOptions_agency_type_id: null,
            selectedOptions_programType: null,
            is_active: '1',
            thumbStyle: themeCSSConstants.THUMBSTYLE,
            thumbIconStyle: themeCSSConstants.THUMBICONSTYLE,
            disableColor: themeCSSConstants.DISABLECOLOR,
            collapseLog: this.props.collapseCard !== undefined ? this.props.collapseCard : true,
            collapsable: this.props.collapseCard !== undefined ? true : false,
            filtered: '',
            agencyAdvanceSearchModal: this.props.Dashboard ? false : true,
            collapse: {
                isOpenAgencyAdvanceSearch: true,
                isOpenAgencyUserPreferences: true,
                isOpenSaveSearch: false,
            },
            search: this.props.agencySearchParams,
            newSearch: {
                searchName: '',
                is_default: false,
            },
            advanceSearchAction: this.props.Dashboard ? '' : 'update',
            defaultSearchId: '',
            inActive_agencies: false,
        };




        this.toggleSettings = this.toggleSettings.bind(this);
        this.handleChangeCompanyType = this.handleChangeCompanyType.bind(this);
        this.handleChangeProgramType = this.handleChangeProgramType.bind(this);
        this.deleteConfirmAgency = this.deleteConfirmAgency.bind(this);
        this.deleteAgency = this.deleteAgency.bind(this);
        this.selectChangeHandler = selectChangeHandler.bind(this);
        this.props.getAgencyTypeCall();
        this.props.getMainProgramTypeCall();
        this.props.getAgencyDefaultPreferenceCall().then((response) => {
            // console.log("response", response.agencyDefaultPreferences.preferences);
            // const search = response && response.preferences;
            // if (search) {

            // }
            this.props.saveAgencySearchParams(response.agencyDefaultPreferences.preferences);
            // this.setState({
            //     search: search
            // }, () => {
            //     console.log("stateeeee", this.state.search);
            //     this.props.saveAgencySearchParams(this.state.search);
            // })
        });
    };

    componentDidMount() {
        this.props.getBrandsCall();
        this.props.getAgencyUserPreferencesCall();
        this.props.getAgenciesCall();
        this.props.getPVSalesRepCall();
        // if (localStorage.getItem('agencySearchParams')) {
        //     const search = JSON.parse(localStorage.getItem('agencySearchParams'));
        //     this.setState({
        //         search: search
        //     })
        // }
        // this.props.saveAgencySearchParams(this.state.search);
        // localStorage.setItem('agencySearchParams', JSON.stringify(this.state.search))
    }


    toggleSettings(id, currentValue, key) {
        this.props.changeAgencySettingStatusCall(id, { 'current_status': currentValue, 'key': key });
    }
    handleChangeCompanyType(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
        this.setState({
            ...this.state,
            selectedOptions_agency_type_id: selectedOptionType
        }, function () {
            this.pagination.dataCall();
        });
        }
        if (info['action'] === 'clear') {
        this.setState({
            ...this.state,
            selectedOptions_agency_type_id: null
        }, function () {
            this.pagination.dataCall();
        });
        }
    }
    handleChangeProgramType(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
        this.setState({
            ...this.state,
            selectedOptions_programType: selectedOptionType
        }, function () {
            this.pagination.dataCall();
        });
        }
        if (info['action'] === 'clear') {
        this.setState({
            ...this.state,
            selectedOptions_programType: null
        }, function () {
            this.pagination.dataCall();
        });
        }
    }
    deleteConfirmAgency(original) {
        confirmAlert({
        customUI: ({ onClose }) => {
            return (
            <Confirmalertfordelete typeName="Agency" agencyAction={original.is_active ? " Inactivate" : "Activate"} description={`Are you sure you want to ${original.is_active? " Inactivate" : "Activate"} the agency`} onClosePro={onClose} deleteType={() => this.deleteAgency(original.id)} />
            )
        }
        })
    }
    deleteAgency(id) {
        this.props.deleteAgencyCall(id).then(Response => {
        this.pagination.dataCall();
        });
    }
    getAgencyDataWithStateValue = (data) => {
        var searchData = {
            search: {...this.state.search.agencyAdvanceSearch}
        };
        console.log("searchData", searchData);
        return agenciesService.getAgenciesPagination(
        data.page,
        this.state.filtered,
        data.sort,
        data.pageSize,
        this.state.selectedOptions_agency_type_id ? this.state.selectedOptions_agency_type_id.value : null,
        this.state.selectedOptions_programType ? this.state.selectedOptions_programType.value : null,
        this.state.is_active,
        searchData
        );
        // return this.props.getAgenciesPaginationCall(data.page, data.filter, data.sort, data.pageSize, this.state.selectedOptions_agency_type_id ? this.state.selectedOptions_agency_type_id.value : null, this.state.selectedOptions_programType ? this.state.selectedOptions_programType.value : null);
    }
    performSearch = () => {
        this.setState({agencyAdvanceSearchModal: false}, function () {
            this.setState({ is_active: this.state.inActive_agencies}, () => {
                this.pagination.dataCall();
            })
            this.props.saveAgencySearchParams(this.state.search);
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.agency_type_id !== this.props.agency_type_id) {
        this.setState({ optionsCompanyType: this.props.agency_type_id });
        }

        if (prevProps.mainProgramType !== this.props.mainProgramType) {
        this.setState({ optionsProgramType: this.props.mainProgramType });
        }
        if (prevProps.changeAgencyStatus !== this.props.changeAgencyStatus) {
        this.pagination.dataCall();
        }
        // if (prevState.agencyAdvanceSearchModal !== this.state.agencyAdvanceSearchModal)  {
        //     if (localStorage.getItem('agencySearchParams')) {
        //         const search = JSON.parse(localStorage.getItem('agencySearchParams'));
        //         this.setState({
        //             search: search
        //         })
        //     }
        //     this.props.saveAgencySearchParams(this.state.search);
        // }
        if (prevProps.agencyDefaultPreferences !== this.props.agencyDefaultPreferences) {

            this.setState({
                newSearch: {...this.state.newSearch, is_default: true},
                defaultSearchId: this.props.agencyDefaultPreferences.id
            });
        }
        // if (prevProps.deleteAgency !== this.props.deleteAgency) {
        //   this.pagination.dataCall();
        // }

        if (this.state.is_active !== prevState.is_active) {
        this.pagination.dataCall();
        // this.table.state.onFetchData(this.table.state, this.table.instance)
        }
    }

    collapseHandle(field) {
        this.setState({
        collapseLog: !this.state.collapseLog
        })
    }

    handleAdvanceSearch = (e) => {
        const {name, value} = e.target;
        const agencyAdvanceSearchState = {...this.state.search.agencyAdvanceSearch};
        agencyAdvanceSearchState[name] = value;
        this.setState(prevState => ({
            ...prevState, search: {
                ...prevState.search, agencyAdvanceSearch: agencyAdvanceSearchState
            }
        }), () => {
            this.props.saveAgencySearchParams(this.state.search);
            localStorage.setItem('agencySearchParams', JSON.stringify(this.state.search));
        });
    }

    handleChangeAdvanceSearchDropDown = (selectedOptionType, info) => {
		console.log("selectedOptionType", selectedOptionType);
		console.log("info", info);
		if (info['action'] === 'select-option' && info['name'] === 'agency_name') {
			const agencyAdvanceSearchDropDown = {...this.state.search.agencyAdvanceSearch};
            agencyAdvanceSearchDropDown[info['name']] = selectedOptionType['label'];
            this.setState(prevState => ({
                ...prevState, search: {
                    ...prevState.search, agencyAdvanceSearch: agencyAdvanceSearchDropDown
                }
            }));
            this.props.saveAgencySearchParams(this.state.search);
		} else if (info['action'] === 'select-option' && info['name'] !== 'agency_name') {
            const agencyAdvanceSearchDropDown = {...this.state.search.agencyAdvanceSearch};
            agencyAdvanceSearchDropDown[info['name']] = selectedOptionType['value'];
            this.setState(prevState => ({
                ...prevState, search: {
                    ...prevState.search, agencyAdvanceSearch: agencyAdvanceSearchDropDown
                }
            }), function () {
                if (info['name'] === 'country') {
                    this.props.getStateCall(selectedOptionType['value'], 'orderList');
                }
                this.props.saveAgencySearchParams(this.state.search);
                localStorage.setItem('agencySearchParams', JSON.stringify(this.state.search))
            });
            this.props.saveAgencySearchParams(this.state.search);
        } else if (info['action'] === 'clear') {
            const agencyAdvanceSearchClear = {...this.state.search.agencyAdvanceSearch};
            agencyAdvanceSearchClear[info['name']] = null;
            if (info['name'] === 'country') {
                agencyAdvanceSearchClear['state'] = null;
            }
            this.setState(prevState => ({
                ...prevState, search: {
                    ...prevState.search, agencyAdvanceSearch: agencyAdvanceSearchClear
                }
            }), () => {
                this.props.saveAgencySearchParams(this.state.search);
                localStorage.setItem('agencySearchParams', JSON.stringify(this.state.search))
            });
        }
    }

    handleChangeBrand = (selectedOptionType, info) => {
        console.log("selected option type", selectedOptionType);
        if (info.name === 'account_id') {
            const agencyAdvanceSearchDropDown = {...this.state.search.agencyAdvanceSearch};
            const agencyAdvanceSearch = cloneDeep(this.state.search.agencyAdvanceSearch);
            if (Array.isArray(selectedOptionType)) {
                if (info.action === 'select-option') {
                    selectedOptionType.forEach(option => {
                    console.log("agencyAdvanceSearch.account_id", agencyAdvanceSearch.account_id);
                    console.log("this.staye.search", this.state.search.agencyAdvanceSearch.account_id);
                        if (!agencyAdvanceSearchDropDown.account_id.includes(option)) {
                            console.log("execute", option);
                            agencyAdvanceSearch[info.name].push(option);
                        }
                    });
                } else if (info.action === 'remove-value') {
                    agencyAdvanceSearch[info.name] = agencyAdvanceSearch[info.name].filter(item => {
                        return item.value !== info.removedValue.value
                    })
                }
            } else {
                if (info.action === 'remove-value') {
                    agencyAdvanceSearch[info.name] = agencyAdvanceSearch[info.name].filter(item => {
                        return item.value !== info.removedValue.value
                    })
                } else if (info.action === 'clear') {
                    agencyAdvanceSearch[info.name] = [];
                } else {
                    agencyAdvanceSearch[info.name] = selectedOptionType.value;
                }
            }
            this.setState(prevState => ({
                ...prevState, search: {
                    ...prevState.search, agencyAdvanceSearch: agencyAdvanceSearch
                }
            }), () => {
                this.props.saveAgencySearchParams(this.state.search);
                localStorage.setItem('agencySearchParams', JSON.stringify(this.state.search))
            });
        }
	}

    filterSearchHandle = (e) => {
            e.persist();
            // console.log('e.key', e.keyCode);
            this.setState({
            'filtered': e.target.value
            }, () => {
            if (e.keyCode === 13) {

                // console.log('e.key one', e.keyCode);
                this.pagination.dataCall();
            }
            });
    }
    openAgencyAdvanceSearchModal = () => {
        this.setState({
            search:
                {
                    ...this.props.agencySearchParams
                }
        }, () => {

            this.setState({agencyAdvanceSearchModal: true, advanceSearchAction: 'create'});
        });
    }

    closeAgencyAdvanceSearchModal = () => {
        this.setState({agencyAdvanceSearchModal: false});
    }

    agencySearchCollapseHandle = (field) => {
        this.setState(prevState => ({
            ...prevState, collapse: {
                ...prevState.collapse, [field]: !this.state.collapse[field]
            }
        }));
    }

    changeStatusForSearch = (status, parentObject) => {
        const stateClone = {...this.state.search[parentObject]};
        const result = setAllTo(status, stateClone);
        this.setState(prevState => ({
            ...prevState, search: {
                ...prevState.search, [parentObject]: result
            }
        }), () => {
            this.props.saveAgencySearchParams(this.state.search);
            localStorage.setItem('agencySearchParams', JSON.stringify(this.state.search))
        });
    }
    handleSearchCheckBox = (e, path, parentObject) => {
        let isChecked = e.target.checked;
        const stateClone = {...this.state.search[parentObject]};
        this.setState(prevState => ({
            ...prevState, search: {
                ...prevState.search, [parentObject]: updateStateVariableDynamic(stateClone, isChecked, path)
            }
        }), () => {
            this.props.saveAgencySearchParams(this.state.search);
            localStorage.setItem('agencySearchParams', JSON.stringify(this.state.search))
        });
    }

    setSearchName = (e) => {
        console.log('check box click');
        const {name, value} = e.target;
        const newSearchState = cloneDeep(this.state.newSearch);
        if (e.target.type === 'checkbox') {
            console.log('check box click 1');
            newSearchState[name] = e.target.checked;
        } else {
            newSearchState[name] = value;
        }
        this.setState({
            ...this.state, newSearch: newSearchState
        })
    }

    submitUserPreferences(searchId) {
        var preferencesData = {
            searchName: this.state.newSearch.searchName,
            is_default: this.state.newSearch.is_default,
            search: {...this.state.search}
        };
        this.props.storeOrUpdateAgencyUserPreferencesCall(preferencesData, searchId).then(
            response => {
                // console.log('response', response);
                this.props.getAgencyUserPreferencesCall();
                // if (response.data.is_default) {
                //     this.props.getDefaultPreferenceCall();
                // }
                this.setState({
                    search: response.data.preferences,
                    // defaultSearchId: response.data.id,
                    newSearch: {...this.state.newSearch, is_default: response.data.is_default}
                }, () => {
                    this.pagination.dataCall();

                    this.setState({advanceSearchModal: false});
                });
            },
            error => {

            }
        );
    }
    savedSearched = () => {
        const {defaultSearchId} = this.state;
        const {agencyUserSearches} = this.props;
        return (
            <div style={{width: '35%'}} className="flexElem alignCenter spaceBetween mb-md">
                <div style={{'width': "calc(45% - 10px)"}} className="selectIndexFix">
                    <label className="container-check mb-none"> <strong>Select Search : </strong></label>
                </div>
                <div style={{'width': "calc(80% - 10px)"}} className="selectIndexFix">
                    <Select
                        value={
                            defaultSearchId ? {
                                id: returnColumnValueWithColumnMatch(agencyUserSearches, 'id', [defaultSearchId], 'id'),
                                searchName: returnColumnValueWithColumnMatch(agencyUserSearches, 'searchName', [defaultSearchId], 'id')
                            } : null
                        }

                        options={agencyUserSearches}
                        onChange={this.handleChangeSearch}
                        getOptionLabel={(option) => option.searchName}
                        getOptionValue={(option) => option.id}
                        className="selectUser"
                        placeholder="Select Search"
                        isMulti={false}
                    />
                </div>
            </div>
        );
    }
    handleChangeSearch = (selectedOptionType, info) => {
        console.log("selectedOptionType 451", selectedOptionType);
        this.setState({
            ...this.state, search: intersectionOfObjectsWithNewValue({
                    agencyAdvanceSearch: OC.AGENCYADVANCESEARCH,
                    agencyUserPreferences: OC.AGENCYUSERPREFERENCES,
                },

                selectedOptionType.preferences
            ),
            defaultSearchId: selectedOptionType.id,
            newSearch: {
                ...this.state.newSearch,
                is_default: selectedOptionType.id === this.props.agencyDefaultPreferences.id ? true : false
            },
        }, () => {
            console.log('178', this.state.newSearch);

            this.props.saveAgencySearchParams(this.state.search);
            localStorage.setItem('agencySearchParams', JSON.stringify(this.state.search))
            this.setState({agencyAdvanceSearchModal: true, advanceSearchAction: 'update'});
        });
    }
    // changeToDefault = () => {
    //     console.log('changeToDefault')
    //     this.setState(prevState => ({
    //         ...prevState,
    //         search: intersectionOfObjectsWithNewValue(
    //             {
    //                 agencyAdvanceSearch: OC.AGENCYADVANCESEARCH,
    //                 agencyUserPreferences: OC.AGENCYUSERPREFERENCES,
    //             },
    //             // this.props.defaultPreferences.preferences
    //         ),
    //         // defaultSearchId: this.props.defaultPreferences.id
    //     }));
    // }
    clearAdvanceSearchForm = () => {
        localStorage.removeItem('searchParams');
            this.setState(prevState => ({
                ...prevState,
                search: {
                    ...prevState.search,
                    agencyAdvanceSearch: OC.AGENCYADVANCESEARCH,
                    agencyUserPreferences: OC.AGENCYUSERPREFERENCES,
                }
            }), () => {
                this.props.saveAgencySearchParams(this.state.search);
            });

    }

    setStateOfParent = (prop) => {
        this.setState({ inActive_agencies: prop });
    }

  render() {
    const { brands, mainProgramType, agency_type_id, salesRep, agenciesOption } = this.props;
    const {agencyUserPreferences, agencyAdvanceSearch} = this.state.search;
    const { selectedOptions_agency_type_id, optionsCompanyType, selectedOptions_programType, optionsProgramType, collapseLog, collapsable, collapse, newSearch, advanceSearchAction, defaultSearchId } = this.state;
    brands.filter(item => {
        return Number(item.is_master) !== 1;
    });
    const columns = [
      {
        Header: "Agencies Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Agency Name",
            accessor: "name",
            className: "action-center",
            Cell: ({ value, original }) => (
              <div>
                {hasPermission('agency-edit') ? <Link target="_blank" to={'/Admin/' + original.id + '/agency-edit'} className="blue_link">{original.name}</Link> : original.name}
              </div>
            ),
            excExp: true,
          },
          {
            Header: "Publishable Agency Name",
            accessor: "publishable_agency_name",
            className: "action-center",
            Cell: ({ value, original }) => (
              <div>
                {hasPermission('agency-edit') ? <Link target="_blank" to={'/Admin/' + original.id + '/agency-edit'} className="blue_link">{original.publishable_agency_name}</Link> : original.publishable_agency_name}
              </div>
            ),
            excExp: true,
          },
          {
            Header: "Website",
            accessor: "website",
            className: "action-center",
            excExp: agencyUserPreferences.website,
            show:agencyUserPreferences.website
          },
          {
            Header: "Agency Type",
            accessor: "agency_type.title",
            className: "action-center",
            Cell: ({ value, original }) => (
              <div>
                {value}
              </div>
            ),
            show: agencyUserPreferences.agency_type.title
            // excExp: agencyUserPreferences.agency_type.title,
          },
          {
            Header: "Primary Contact",
            accessor: "primary_contacts.name",
            Cell: ({ value, original }) => (
              <div>
                <OverlayTrigger trigger='click' placement="bottom" overlay={
                  <Popover id="tooltip">
                    {
                      original.primary_contacts &&
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <span>{'Name: ' + original.primary_contacts.name}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>{'Phone: ' + original.primary_contacts.phone1}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>{'Email: ' + original.primary_contacts.email}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }

                    {
                      !original.primary_contacts &&
                      <span>N/A</span>
                    }
                  </Popover>
                }>
                  <span style={{ cursor: 'pointer' }}>
                    {
                      original.primary_contacts &&
                      original.primary_contacts.name
                    }

                    {
                      !original.primary_contacts &&
                      <span>N/A</span>
                    }
                  </span>
                </OverlayTrigger>

              </div>
            ),
            className: "action-center",
            excExp: true,
            show: agencyUserPreferences.primary_contacts.name
          },
          {
            Header: "Primary Contact Email",
            accessor: "primary_contacts.email",
            className: "action-center",
            excExp: true,
            show:agencyUserPreferences.primary_contacts.email
          },
          {
            Header: "Primary Contact Number",
            accessor: "primary_contacts.phone1",
            className: "action-center",
            excExp: true,
            show:agencyUserPreferences.primary_contacts.phone1
          },
          {
            // id: 'program_type',
            Header: "Program Type",
            accessor: "program_type.programTypeWithPrice",
            // accessor: d => d.program_type.title+'-'+d.program_type.price,
            className: "action-center",
            excExp: agencyUserPreferences.program_type.title,
            show: agencyUserPreferences.program_type.title
          },
          {
            Header: "Quick Link to agency profile",
            accessor: "users",
            className: "action-center",
            Cell: ({value}) => (
                <div>
                    {
                        value && value[0] &&
                        returnAgencyQuickURL(value[0].username, atob(value[0].enc_password))

                    }
                </div>
            ),
            excExp: agencyUserPreferences.quick_link_to_agency_profile,
            show: agencyUserPreferences.quick_link_to_agency_profile
          },
          {
            Header: "Brand",
            accessor: "accounts[0].domain_slug",
            className: "action-center",
            excExp: agencyUserPreferences.brand,
            show: agencyUserPreferences.brand
          },
          {
            Header: "GTV Sales Rep",
            accessor: "sales_representative.name",
            className: "action-center",
            excExp: agencyUserPreferences.gtv_sales_rep,
            show: agencyUserPreferences.gtv_sales_rep
          },
          {
            Header: "Agency Primary Street",
            accessor: "primary_address.street_address_1",
            className: "action-center",
            excExp: agencyUserPreferences.primary_address.street_address_1,
            show:agencyUserPreferences.primary_address.street_address_1
          },
          {
            Header: "Agency Primary City",
            accessor: "primary_address.city",
            className: "action-center",
            excExp: agencyUserPreferences.primary_address.city,
            show:agencyUserPreferences.primary_address.city
          },
          {
            Header: "Agency Primary State",
            accessor: "primary_address.state.title",
            className: "action-center",
            excExp: agencyUserPreferences.primary_address.state,
            show:agencyUserPreferences.primary_address.state
          },
          {
            Header: "Agency Primary Zip",
            accessor: "primary_address.zipcode",
            className: "action-center",
            excExp: agencyUserPreferences.primary_address.zipcode,
            show:agencyUserPreferences.primary_address.zipcode
          },
          {
            Header: "Accounting Contact",
            accessor: "accounting_contacts.name",
            Cell: ({ value, original }) => (
              <div>
                <OverlayTrigger trigger='click' placement="bottom" overlay={
                  <Popover id="tooltip">
                    {
                      original.accounting_contacts &&
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <span>{'Name: ' + original.accounting_contacts.name}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>{'Phone: ' + original.accounting_contacts.phone1}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>{'Email: ' + original.accounting_contacts.email}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }

                    {
                      !original.accounting_contacts &&
                      <span>N/A</span>
                    }

                  </Popover>
                }>
                  <span style={{ cursor: 'pointer' }}>
                    {
                      original.accounting_contacts &&
                      original.accounting_contacts.name
                    }

                    {
                      !original.accounting_contacts &&
                      <span>N/A</span>
                    }
                  </span>
                </OverlayTrigger>

              </div>
            ),
            className: "action-center",
            excExp: true,
          },
          {
            Header: "Created Date",
            accessor: "created_at",
            className: "action-center",
            Cell: ({ value }) => (
                <div>
                {value}
              </div>
            ),
            show: agencyUserPreferences.created_at,
            excExp: true,
          },
          {
            Header: "Agency All User Name/Email",
            accessor: "id",
            className: "action-center",
            Cell: ({ value }) => (
              <div>
                {<Link target="_blank" to={'/Admin/' + value + '/agency-users'} className="blue_link">Agency Users</Link>}
              </div>
            ),

            show: agencyUserPreferences.agency_all_users_name,
            excExp: agencyUserPreferences.agency_all_users_name,
          },
        //   {
        //     Header: "Agency All User Email",
        //     accessor: "created_at",
        //     className: "action-center",
        //     Cell: ({ value }) => (
        //       <div>
        //         {<Link target="_blank" to={'/Admin/' + value + '/agency-users'} className="blue_link">Click to view</Link>}
        //       </div>
        //     ),
        //     show: agencyUserPreferences.agency_all_users_email,
        //     excExp: agencyUserPreferences.agency_all_users_email,
        //   },
          {
            Header: "Keep High Resolution Photos",
            accessor: "settings[0].value",
            Cell: ({value}) => (
                <div>
                    {
                        value === '1'
                        ?
                        'Yes'
                        :
                        'No'

                    }
                </div>
            ),
            className: "action-center",
            show: agencyUserPreferences.keep_high_resolution_photos,
            excExp: agencyUserPreferences.keep_high_resolution_photos,
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value, original }) => (
              <div>
                <ButtonToolbar>
                  <OverlayTrigger trigger="focus" placement="bottom" overlay={
                    <Popover id="tooltip">
                      <table className="table text-left actions_table">
                        <tbody>
                          <tr>
                            <td>
                              {
                                hasPermission('agency-edit') &&
                                <Link to={'/Admin/' + value + '/agency-edit'} className=""><i className="fa fa-pencil" /> Edit</Link>

                              }
                              {
                                !hasPermission('agency-edit') &&
                                <Link to={'/Admin/' + value + '/agency-edit'} className="btn_link " ><i className="fa fa-eye" /> View</Link>
                              }
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {
                                hasPermission('agency-delete') &&
                                <button onClick={() => this.deleteConfirmAgency(original)} className={!original.is_active ? "btn_link" : "btn_link text-danger"}><i className={!original.is_active ? "fa fa-check" : "fa fa-trash text-danger"} /> {original.is_active ?" Inactivate" : "Activate"}</button>

                              }
                              {
                                !hasPermission('agency-delete') &&
                                <button type='button' className="btn_link button_disabled" ><i className={!original.is_active ? "fa fa-check" : "fa fa-trash text-danger"} /> {original.is_active ? " Inactivate" : "Activate"}</button>
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {
                                hasPermission('agency-user-list') &&
                                <Link to={'/Admin/' + value + '/agency-users'} className=""><i className="fa fa-users" /> Agency Users</Link>

                              }
                              {
                                !hasPermission('agency-user-list') &&
                                <button type='button' className="btn_link button_disabled" ><i className="fa fa-users" /> Agency Users</button>

                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {
                                hasPermission('agency-resource-list') &&
                                <Link to={'/Admin/' + value + '/agency-resource'} className=""><i className="fa fa-file" /> Agency Resources</Link>

                              }
                              {
                                !hasPermission('agency-resource-list') &&
                                <button type='button' className="btn_link button_disabled" ><i className="fa fa-file" /> Agency Resources</button>

                              }
                            </td>
                          </tr>


                        </tbody>
                      </table>

                    </Popover>
                  }>
                    <span>
                      <button type='button' className="action-button-size" ><i className="fa fa-bars" /></button>
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>

              </div>
            ),
            className: "action-center",
            sortable: false,
            excExp: false,
          }
        ]
      }
    ];

    const options = [
      {
        value: '',
        label: 'All'
      },
      {
        value: '1',
        label: 'Active'
      },
      {
        value: '0',
        label: 'In-Active'
      }
    ]
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                bsClass={["innerCard relativeCard"]}
                collapsHandler={() => this.collapseHandle('collapseLog')}
                collapsable={collapsable}
                title="Agencies List"
                collapseTrigger={collapseLog}
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        {/* <Col md={12}>
                          <div className="secHeading">Agencies List</div>
                        </Col> */}
                        <Col md={12} className="mt-md">
                          <Pagination
                            ref={(p) => this.pagination = p}
                            columns={columns}
                            getDataCall={this.getAgencyDataWithStateValue}
                            filterView={false}
                            showAllToggle={true}
                            pageSize={50}
                            upperContent={
                              <React.Fragment>
                                <Col md={2} sm={6} xs={12}>
                                    <div className="form-group" style={{ "marginBottom": "9px" }}>
                                        {
                                        hasPermission('agency-create') &&
                                        <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Add Agency</Popover>} >
                                            <Link to="/Admin/agency-create" className="backButton btn-block pt-sm text-xs no_radius pb-sm primary btn btn-lg btn-info"><i className="fa fa-plus" />Agency</Link>
                                        </OverlayTrigger>
                                        }
                                        {
                                        !hasPermission('agency-create') &&
                                        <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{adminLabels.DONT_ALLOW}</Popover>} >
                                            <button to="/Admin/agency-create" className="button_disabled backButton pt-sm no_radius pb-sm primary btn btn-lg btn-block btn-info"><i className="fa fa-plus" />Agency</button>
                                        </OverlayTrigger>
                                        }
                                    </div>
                                    <div>
                                        <OverlayTrigger
                                            placement="bottom"
                                            trigger={['hover']}
                                            overlay={<Popover
                                                id="tooltip">Search Agency</Popover>}>
                                            <Button type='button'
                                                    className="backButton pt-sm no_radius btn-block pb-sm primary mt-none btn btn btn-info "
                                                    onClick={this.openAgencyAdvanceSearchModal}><i
                                                className="fa fa-plus"/>Search</Button>
                                        </OverlayTrigger>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12} >
                                  <div className="form-group select_height_adjust">
                                    <Select
                                      value={selectedOptions_agency_type_id}
                                      onChange={this.handleChangeCompanyType}
                                      options={optionsCompanyType}
                                      isSearchable={true}
                                      isMulti={false}
                                      name='agency_type_id'
                                      placeholder={'Select Agency Type'}
                                      isClearable={true}
                                      className=""
                                    />

                                    <Select
                                      isSearchable={false}
                                      isMulti={false}
                                      styles={
                                        {
                                          container: (provided) => {
                                          return {
                                            ...provided,
                                            width: '100%',
                                            minWidth: '200px'
                                          }
                                          },
                                          menu: (provided) => {
                                            return {
                                              ...provided,
                                              width: '200px'
                                            }
                                          }
                                        }
                                      }
                                      value={
                                        options.map(item => {
                                          if (item.value === this.state.is_active) {
                                            return item
                                          }
                                        })
                                      }
                                      className='mt-md'
                                      options={options}
                                      onChange={this.selectChangeHandler}
                                      name='is_active'
                                    />
                                  </div>
                                </Col>
                                <Col md={3} sm={6} xs={12} >
                                  <div className="form-group select_height_adjust">
                                    <Select
                                      value={selectedOptions_programType}
                                      onChange={this.handleChangeProgramType}
                                      options={optionsProgramType}
                                      isSearchable={true}
                                      isMulti={false}
                                      name='programType'
                                      placeholder={'Select Program Type'}
                                      isClearable={true}
                                      className=""
                                    />
                                  </div>

                                  <div className='mt-lg'>
                                        <Link target="_blank" to={'/Admin/all-agencies-users'} className="blue_link">Show All Agencies Users</Link>
                                  </div>
                                </Col>

                                <Col className='pull-right' md={4} sm={6} xs={12} >
                                  <FormInputs
                                    ncols={["col-md-12"]}
                                    // onChange={this.filterSearchHandle}
                                    // onKeyDown={this.filterSearchHandle}
                                    proprieties={[
                                      {
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "Search Agencies",
                                        // onChange: this.filterSearchHandle,
                                        onKeyDown: this.filterSearchHandle,
                                        name: "filter"
                                      }
                                    ]}
                                  />
                                </Col>

                              </React.Fragment>
                            }
                            downloadFileName={'Agency'}
                            downloadData={true}
                          // lowerContent = {null}
                          // filterPlaceHolder={'Agency'}
                          //getRowProps = { this.getRowProps }
                          />
                        </Col>
                      </Row>
                    </Grid>


                    <Modal backdrop={'static'} show={this.state.agencyAdvanceSearchModal} onHide={this.closeAgencyAdvanceSearchModal}
                        aria-labelledby="ModalHeader" className="advanceSearch">
                        <Modal.Header closeButton>
                            <Modal.Title id='ModalHeader' className="headerTitle">Search</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="content">

                                {
                                    this.savedSearched()
                                }
                                <AgencyAdvanceSearchForm
                                    componentPro="additionalFilters"
                                    advanceSearch={agencyAdvanceSearch}
                                    handleAdvanceSearch={this.handleAdvanceSearch}
                                    handleChangeAdvanceSearchDropDown={this.handleChangeAdvanceSearchDropDown}
                                    handleChangeBrand={this.handleChangeBrand}
                                    onChangsAdvanceSearchDate={this.onChangsAdvanceSearchDate}
                                    clearAdvanceSearchForm={this.clearAdvanceSearchForm}
                                    options={
                                        {
                                            'agencies': agenciesOption,
                                            'salesRep': salesRep,
                                            'agencyTypes': agency_type_id,
                                            'mainProgramType': mainProgramType,
                                            'brands': brands
                                        }
                                    }
                                    performSearch={this.performSearch}
                                    isOpen={collapse.isOpenAgencyAdvanceSearch}
                                    agencySearchCollapseHandle={this.agencySearchCollapseHandle}
                                    setStateOfParent={this.setStateOfParent}
                                />
                                <AgencyUserPreferences
                                    closeModalUserPro={this.closeagencyAdvanceSearchModal}
                                    handleSearchCheckBoxPro={this.handleSearchCheckBox}
                                    componentPro="advanceSearch"
                                    agencyUserPreferences={agencyUserPreferences}
                                    changeStatusForSearch={this.changeStatusForSearch}
                                    // submitUserPreferences={this.submitUserPreferences}
                                    isOpen={collapse.isOpenAgencyUserPreferences}
                                    performSearch={this.performSearch}
                                    collapseHandle={this.collapseHandle}
                                    changeToDefault={this.changeToDefault}
                                    hide={() => {
                                        this.setState({agencyAdvanceSearchModal: false})
                                    }}
                                />
                                {

                                    <React.Fragment>
                                        <div className="flexElem alignCenter form-group justifyCenter">
                                            {
                                                collapse.isOpenSaveSearch &&
                                                <div className='m-sm'>
                                                    <input type="text" className="form-control" name="searchName"
                                                        onChange={this.setSearchName}/>
                                                </div>
                                            }
                                            <div className='m-sm'>
                                                <label className="container-check mb-none"> Is default <input
                                                    type="checkbox" name="is_default" className="checkbox"
                                                    onChange={this.setSearchName} checked={newSearch.is_default}/><span
                                                    className="checkmark"></span></label>
                                            </div>
                                            {
                                                collapse.isOpenSaveSearch &&
                                                <div className='m-sm'>
                                                    <button onClick={() => this.submitUserPreferences('')}
                                                            className="backButton pt-sm no_radius pb-sm success btn btn-info ">Save
                                                        Search
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                    </React.Fragment>
                                }
                                <button className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm"
                                        onClick={() => {
                                            this.setState({
                                                ...this.state,
                                                collapse: {
                                                    ...this.state.collapse,
                                                    isOpenSaveSearch: !this.state.collapse.isOpenSaveSearch
                                                }
                                            })
                                        }}>{collapse.isOpenSaveSearch ? 'Cancel' : 'Create New Search'}</button>
                                {
                                    (advanceSearchAction === 'update' && !collapse.isOpenSaveSearch) &&
                                    <button
                                        className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm"
                                        onClick={() => this.submitUserPreferences(defaultSearchId)}>Update</button>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    getAgenciesPaginationCall: (page, filter, sort, pageSize, filterCompanyType, filterProgramType, searchData) => { return dispatch(getAgenciesPagination(page, filter, sort, pageSize, filterCompanyType, filterProgramType, searchData)) },
    getAgencyTypeCall: () => { dispatch(getAgencyType()) },
    // getProgramTypeCall: () => { dispatch(getProgramType()) },
    getMainProgramTypeCall: () => { dispatch(getMainProgramType()) },
    changeAgencySettingStatusCall: (agencyId, settingData) => { dispatch(changeAgencyStatus(agencyId, settingData)) },
    deleteAgencyCall: (id) => { return dispatch(deleteAgency(id)) },
    getBrandsCall: () => { dispatch(getBrands()) },
    saveAgencySearchParams: (data) => { return dispatch(saveAgencySearchParams(data)) },
    getPVSalesRepCall: () => { dispatch(getSalesRep()) },
    storeOrUpdateAgencyUserPreferencesCall: (preferencesData, searchId) => { return dispatch(storeOrUpdateAgencyUserPreferences(preferencesData, searchId)) },
    getAgencyUserPreferencesCall: () => { dispatch(getAgencyUserPreferences()) },
    getAgencyDefaultPreferenceCall: () => { return dispatch(getAgencyDefaultPreference()) },
    getAgenciesCall: () => {
        dispatch(getAllAgencies())
    },
  });
};

function mapStateToProps(state) {
  const { loadingSettings, agency_type_id, mainProgramType, salesRep, agencyUserSearches, agencyDefaultPreferences } = state.settingsReducer;
  const { loadingAgency, changeAgencyStatus, deleteAgency, agencySearchParams, agenciesOption } = state.agenciesReducer;
  const { brands } = state.accountReducer;
  return {
    loadingAgency, loadingSettings, agency_type_id, mainProgramType, changeAgencyStatus, deleteAgency, agencySearchParams, brands, salesRep, agencyUserSearches, agencyDefaultPreferences, agenciesOption
  };
};

const AgencyList = connect(mapStateToProps, mapDispatchToProps)(AgencyListPage);
export default AgencyList;
