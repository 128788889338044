import React, { Component } from "react";
import { connect } from 'react-redux';
import { getReceivingReports } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import MoreButtonsPagination from 'components/ThemeComponents/MoreButtonsPagination';
import {alertActions} from 'redux/actions/alert-actions';
import { SC } from 'helper/ServerCall';
import { Link } from 'react-router-dom';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Select from 'react-select';
import { selectChangeHandler, dateFormatMy } from 'helper/helperFunctions';
import { orderService } from 'services/order';

const moment = window.moment;

class AiJobsReports extends Component {
	constructor(props) {
		super(props);
		this.validator = new ReactValidator();
		this.state = {

			numOfDays: 30,
			collapseUiSettings: true,
            is_active: '',
		}
		this.getOrderMissingAgencyResourceCallWithNumDays = this.getOrderMissingAgencyResourceCallWithNumDays.bind(this);
        this.selectChangeHandler = selectChangeHandler.bind(this);
	}
	getOrderMissingAgencyResourceCallWithNumDays(data) {
		data = { ...data, numOfDays: this.state.numOfDays };
		return this.props.getReceivingReportsCall(data);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.is_active !== this.state.is_active) {
			this.pagination.dataCall();
		}
	}
	handleChangeContactLogDays = (selectedOptionType, info) => {
		if (info['action'] === 'select-option') {
			this.setState({ numOfDays: selectedOptionType['value'] });
		}
	}

	paginationCall = (data) => {
		return orderService.getAiOrderStatus(data.page, this.state.is_active, data.sort, data.pageSize).then(response => {
			console.log(response, 'responseee');
			return response;
		});
	}

	updateAiJobSortOrders = async (data) => {
		try {
		  const res = await SC.postCall('updateAiJobSortOrders' , data );
		  this.props.success(res.message);
		} catch (error){
		  this.props.error(error);
	
		}
	}
	capitalizeFirstLetterOfEachWord = (str) => {
		return str.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
	}
	aiStatus = (record) => {
		let lbl = '';
		let status = '';
		// let result = '';
		if ( record && record.ai_status ) {
			lbl = record.ai_status[0].status;
			lbl = this.capitalizeFirstLetterOfEachWord(lbl)
			status = lbl;
			if ( lbl === 'Completed' || lbl === 'Failed' ) {//in progress
				status = lbl  + ' at ' + record.ai_status[0].ai_process_date + '';
			} else if ( lbl === 'In Progress' ) {//in progress
				let time = Math.floor(Number(record.ai_remaining_time) - Number(record.original_images_count * 7.5));
				status = Math.floor(time / 60);
				// result = this.formatMinutesToTimeUnits(status);
				status = lbl + ' ' + ' ' + status + ' Min(s) Remaining';
			}
		}
		return status;
	}
	render() {
		//const { collapseUiSettings, filtered, numOfDays } = this.state;
		const { collapseUiSettings, numOfDays } = this.state;
		const columns = [
			{
				Header: "Report Testing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Barcode",
						accessor: "barcode",
						className: "action-center",
						Cell: ({ value, original }) => {
							return <Link to={'/Admin/' + (original.reorder ? original.reorder : original.id) + '/order-edit'} target="_blank" className="blue_link">{value}</Link>
						},
                        width: 200,
						height: 100,
						sortable: false,
					},
					{
						Header: "Agency Name",
						accessor: "agency.name",
						Cell: ({ value }) => (
						<div>
							{value}
						</div>
						),
						// className: "justifyStart",
						sortable: false,
						height: 100,
					},
					{
						Header: "Group Type",
						accessor: `agency_type.title`,
						Cell: ({ value, original }) => (
						<div >
							{value}
						</div>
						),
						// className: "justifyStart",
						width: 170,
						backgroundColor: 'pink',
						sortable: false,
						height: 100,
					},

					{
						Header: "Group Name",
						accessor: "group_name",
						className: "action-center",
						sortable: false,
						height: 100,
					},
					{
						Header: "Status",
						accessor: "group_name",
						className: "action-center",
						sortable: false,
						height: 100,
						Cell: ({ value, original }) => (
							<div><small>
								{
									this.aiStatus(original)
								}</small>
							</div>
						)
					},
					{
						id: 'order_dates.return_date.value',
						Header: "Return Date",
						accessor: "return_date",
						className: "action-center",
						sortable: false,
						height: 100,
						Cell: ({ value, original }) => (
						<div>
							{dateFormatMy(value) }
						</div>
						),
					},
					
				]
			}
		];
		const options = [
			{
			  value: '',
			  label: 'All'
			},
			{
				value: 'failed',
				label: 'Failed'
			},
			{
			  value: 'completed',
			  label: 'Completed'
			},
			{
			  value: 'in progress',
			  label: 'In Progress'
			},
		  ]
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
                                                <Col md={12}>
                                                    <div className="secHeading">
                                                        Ai Jobs Dashboard
                                                    </div>
													<Col md={3} sm={6} xs={12} >
														<div className="form-group select_height_adjust">
															<Select
															isSearchable={false}
															isMulti={false}
															styles={
																{
																container: (provided) => {
																return {
																	...provided,
																	width: '100%',
																	minWidth: '200px'
																}
																},
																menu: (provided) => {
																	return {
																	...provided,
																	width: '200px'
																	}
																}
																}
															}
															value={
																options.map(item => {
																if (item.value === this.state.is_active) {
																	return item
																}
																})
															}
															className='mt-md'
															options={options}
															onChange={this.selectChangeHandler}
															name='is_active'
															/>
														</div>
														</Col>
												</Col>
												
												<Col md={12} className="mt-md">
													<MoreButtonsPagination
														ref={(p) => this.pagination = p}
														columns={columns}
														getDataCall={this.paginationCall}
														filterView={false}
														pageSize={100}
														updateSort={this.updateAiJobSortOrders}
													/>
												</Col>

											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		getReceivingReportsCall: (data) => { return dispatch(getReceivingReports(data)) },
		success: (message) => { return dispatch(alertActions.success(message)) },
		error: (message) => { return dispatch(alertActions.error(message)) },
	});
};

export default connect(mapStateToProps, mapDispatchToProps)(AiJobsReports);
