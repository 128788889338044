import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getOrder, allOrderImagesByPhase, storeEditorOrderImages, assignUserToOrder, getOrderImagesPath, storeOrderImagesInDB, allowSave, disableSave, producedImagesAi } from 'redux/actions/order-actions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import { alertActions } from '../../../redux/actions/alert-actions';
import { closeModal, uuid } from '../../../helper/helperFunctions';
import { Modal} from "react-bootstrap";
import Axios from 'axios';
import AWS from 'aws-sdk';
import EXIF from 'exif-js';
import { confirmAlert } from 'react-confirm-alert';
import { toast, Slide, Rotate, Flip } from 'react-toastify';
import {BiLoaderAlt} from 'react-icons/bi';
import { orderService } from 'services/order';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import { showLoader } from 'redux/actions/settings-actions';
var cloneDeep = require('lodash.clonedeep');

class AiAdditionalImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAdditionalImageModal: false,
            workingImages: [],
			copyOfWorkingImages: [],
            imageData: [],
            uploadedFiles: 0,
        }
    }
    setResourceFile = (key, value) => {
		const CancelToken = Axios.CancelToken;
		const source = CancelToken.source();
		value.axiosToken = source;
		this.setState((prevState) => {
			return {
				workingImages: [
					...prevState.workingImages,
					value
				]
			}
		});
	}
	updateResourceFile = (index, fileObject) => {
		const workingImagesState = cloneDeep(this.state.workingImages);
		const CancelToken = Axios.CancelToken;
		const source = CancelToken.source();
		fileObject.axiosToken = source;
		workingImagesState[index] = fileObject;
		this.setState({
			workingImages: workingImagesState,
		});
	}
	removeFile = (key, index) => {
		const workingImagesState = [...this.state.workingImages]
		workingImagesState.splice(index, 1);
		this.setState({
			workingImages: workingImagesState,
		});
	}
    clearAllTempImages = () => {
		this.setState({
			workingImages: [],
		});
	}
    saveImages = () => {
		
        this.setState({
            allowSave: true
        })
        this.props.allowSaveCall(true);
		this.props.showLoaderCall(false);
		const workingImages = [...this.state.workingImages];

		let temp_files = [...this.state.workingImages];
		this.setState({
			copyOfWorkingImages: temp_files
		});

        const orderImagePath = this.props.orderImagePath;
		this.toastIdImg = null;
			this.toastIdImg =  toast(<React.Fragment>Images are Uploading. Please do not leave this page! {this.state.uploadedFiles +'/'+ workingImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
				progress: workingImages.length,
				type: toast.TYPE.INFO,
				position: "bottom-right",
                autoClose: true
			});

		try{
			const { REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_EDITED_BUCKET, REACT_APP_AWS_DEFAULT_REGION, REACT_APP_AWS_SECRET_ACCESS_KEY  } = process.env;
			var S3_BUCKET = REACT_APP_AWS_EDITED_BUCKET + '/' + orderImagePath;
			const REGION = REACT_APP_AWS_DEFAULT_REGION;
			const https = require('https');
			AWS.config.update({
			  httpOptions: {
				agent: new https.Agent({
				  secureProtocol: 'TLSv1_2_method',
				  ciphers: 'ALL',
				}),
			  },
				accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
				secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY
			})
			var myBucket = new AWS.S3({
				params: { Bucket: S3_BUCKET},
				region: REGION,
				maxRetries: 20, // retry 10 times
                useAccelerateEndpoint: true
				//retryDelayOptions: { base: 200 } // see AWS.Config for information
			})
		} catch (err) {
			console.log(err);
		}

		this.uploadFiles(myBucket, S3_BUCKET);                   //  start the loop
    }

    wait = ms => {return new Promise(resolve => setTimeout(resolve, ms))}
    //  set your counter to 1
	uploadFiles = async (myBucket, S3_BUCKET) => {
        // this.props.showLoaderCall(true);
		var delay = 500;
		if(this.state.copyOfWorkingImages.length % 20 === 0 && this.state.copyOfWorkingImages.length > 0) {
			delay = 5000;
		}

		let self = this;
		//  create a loop function
		setTimeout(() => {   //  call a delay seconds setTimeout when the loop is called
			let workingImages = [...this.state.workingImages];

			let arr = [...this.state.copyOfWorkingImages];

			if(arr) {
				// At position 0, get 1 items:
				let file_slice = arr.slice(0, 1);
				var file = file_slice[0];
				// At position 0, remove 1 item:
				arr.splice(0, 1);
				this.setState({
					copyOfWorkingImages: arr,
				});
			}
			if(file == undefined || !file || !file.name || !arr) {
				return;
			}

			let u_id = uuid().toString();
			let ext =  file.name.split('.').pop();
			var currentImageName = u_id + '.' + ext;
			EXIF.getData(file, function() {
				const exifData = EXIF.pretty(this);
				let Data = {};
				if (exifData) {
					Data = {
						file_size : file.size,
						file_title : file.name,
						file_type : file.type,
						original_file_name : u_id + '.' + ext,
						exif_orientation : EXIF.getTag(this, "Orientation") ? EXIF.getTag(this, "Orientation") : null,
						use_in_highlight_video  : EXIF.getTag(this, "use_in_highlight_video") ? EXIF.getTag(this, "use_in_highlight_video") : 0,
						exif_date_taken : EXIF.getTag(this, "DateTime") ? EXIF.getTag(this, "DateTime") : null,
						DateTimeOriginal : EXIF.getTag(this, "DateTimeOriginal") ? EXIF.getTag(this, "DateTimeOriginal") : null,
						ModifyDate : EXIF.getTag(this, "ModifyDate") ? EXIF.getTag(this, "ModifyDate") : null,
						exif_manufacturer : EXIF.getTag(this, "Make") ? EXIF.getTag(this, "Make") : null,
						exif_camera_model : EXIF.getTag(this, "Model") ? EXIF.getTag(this, "Model") : null,
						exif_long   : EXIF.getTag(this, "GPSLongitude") ? EXIF.getTag(this, "GPSLongitude") : null,
						exif_lat : EXIF.getTag(this, "GPSLatitude") ? EXIF.getTag(this, "GPSLatitude") : null,
						height : EXIF.getTag(this, "PixelXDimension") ? EXIF.getTag(this, "PixelXDimension") : null,
						width  : EXIF.getTag(this, "PixelYDimension") ? EXIF.getTag(this, "PixelYDimension") : null,
					};
				} else {
					Data = {
						file_size : file.size,
						file_title : file.name,
						file_type : file.type,
						original_file_name : u_id + '.' + ext
					};
				}

				if(Data) {
					let tmp_elm = [...self.state.imageData];
					tmp_elm.push(Data);
					self.setState({
						imageData : tmp_elm,
					});
				}
			});

			try{

				const params = {
					ACL: 'public-read',
					Body: file,
					Bucket: S3_BUCKET,
					Key: currentImageName,
					ContentType: file.type || 'application/octet-stream', // Set the correct MIME type
				};
				setTimeout(() => {
					myBucket.putObject(params, (err, data)=>{


						if (err) {
							console.log('putObject err occored ', file.name, ' ', this.state.uploadedFiles, err, 'myBucket.putObject err.stack ', err.stack); // an error occurred
						}
						else {
							console.log('myBucket.putObject response ', file.name, ' ', this.state.uploadedFiles);  
						}

						const uploaded = this.state.uploadedFiles;
						this.setState({
							uploadedFiles: uploaded + 1
						},()=>{
								if (self.state.uploadedFiles === workingImages.length) {
                                    this.setState({ showCancelButton: false})
									toast.dismiss(self.toastIdImg);
									setTimeout(() => {

										const imageData = {images: self.state.imageData , uploaded_by_first_name: self.state.firstName, uploaded_by_last_name: self.state.lastName} ;
										orderService.storeAdditionalImagesAi(imageData, self.props.orderId, 'working').then(

												response => {
													this.props.success(response.message);
													self.setState({
															workingImages: [],
															imageData: [],
															responseArray: [],
			                                                copyOfWorkingImages: [],
															uploadedFiles: 0,
															collapseImageUploaderAndViewer: false,
                                                            allowSave: false,
                                                            showCancelButton: true,
                                                            openAdditionalImageModal: false,
														})
                                                        this.props.disableSaveCall(false);
                                                        this.props.producedImagesAiCall(this.props.orderId);
												},
												error => {
													console.log('error');
												}
												)

									}, 1000);
								}
								else {
									toast.update(self.toastIdImg, {
										render: <React.Fragment>Images are Uploading. Please do not leave this page! {self.state.uploadedFiles +'/'+ workingImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>,
										progress: workingImages.length,
										type: toast.TYPE.INFO,
										position: "bottom-right",
									});
								}
						})

					});
				}, delay);

			} catch (err) {
				console.log('S3 put object error', err);
				Object.keys(err).map(x => {
					file['message'] = err[x];
				});
			}

			if (this.state.uploadedFiles < workingImages.length) {           //  if the counter < 10, call the loop function
				this.uploadFiles(myBucket, S3_BUCKET);             //  ..  again which will trigger another
			}                       //  ..  setTimeout()			
		}, delay)
	}

    render() {
        const { openAdditionalImageModal, workingImages, allowSave } = this.state;
        return (
            <div>
                <span className='ml-sm'>
                    <button name='resort_ai_images' className="customBtn btn btn-primary btn-default" onClick={() => {this.setState({openAdditionalImageModal: !this.state.openAdditionalImageModal})}} >Additional Images</button>
                </span>

                <Modal bsSize="lg" backdrop={'static'} show={openAdditionalImageModal} id="" onHide={closeModal.bind(this, "openAdditionalImageModal")} aria-labelledby="ModalHeader" className="" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Additional Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="clearfix mb-md mt-xs text-right">
                                {
                                    workingImages.length > 0 &&
                                    <span style={{ 'color': 'red' }} >Count of images to upload : {workingImages.length}</span>
                                }

                                {
                                    workingImages.length > 0 &&
                                    <button type="button" disabled={allowSave} className="orderDetailsAction finalizeIcon ml-md" onClick={this.saveImages}>Save</button>
                                }
                                {
                                    workingImages.length > 0 &&
                                    <button type="button" disabled={allowSave} className="orderDetailsAction clearIcon ml-md" onClick={this.clearAllTempImages}>Clear ALL</button>
                                }

                            </div>
                            <React.Fragment>
                                <span className="customUploader"></span>
                                <FileUpload
                                    files={workingImages}
                                    setResourceFile={this.setResourceFile}
                                    updateResourceFile={this.updateResourceFile}allowedFormatsStringMimePro={'image/jpeg, image/png'}
                                    allowedFormatsStringExtPro={'jpg, png'}
                                    askToUpdate={true}
                                    updateWithoutAsking={false}
                                    allowDuplicate={false}
                                    multiple={true}
                                />
                            </React.Fragment>

                            <ImagePreview
                                filesToPreview={workingImages}
                                removeFile={this.removeFile}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { orderImagePath, allowSave } = state.ordersReducer;
    return {
        orderImagePath, allowSave 
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        producedImagesAiCall: (orderId) => { return dispatch(producedImagesAi(orderId)); },
        success: (message) => { return dispatch(alertActions.success(message)) },
		allOrderImagesByPhaseCall: (orderId, version_type, tabName) => { dispatch(allOrderImagesByPhase(orderId, version_type, tabName)) },
        allowSaveCall: (flag) => { dispatch(allowSave(flag)) },
        disableSaveCall: (flag) => { dispatch(disableSave(flag)) },
		showLoaderCall: (status) => { dispatch(showLoader(status)) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AiAdditionalImages);