import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { Card } from "components/Card/Card.jsx";

import {
	Button,
	// Col,
	OverlayTrigger,
	Tooltip,
	// Popover
} from "react-bootstrap";
import {
    storeAgency,
    editAgency,
    getAllAgencies,
    updateAgencyInventory, deleteAgency, getAgency
} from 'redux/actions/agency-actions';
import {
    getAgencyType,
    getSalesRep,
    getCountries,
    getProgramType,
    getSettingValue,
    clearSettings
} from 'redux/actions/settings-actions';
import { connect } from 'react-redux';
import {
    focusInput,
    returnColumnValueWithColumnMatch,
    returnLabelWithValue,
    returnLabelWithValueGroup,
    closeModal,
} from 'helper/helperFunctions';
import MoreButtonsList from '../MoreButton/MoreButtonsList'
import MoreButtonsWeTravel from '../MoreButton/MoreButtonsWeTravel'
import { hasPermission } from 'helper/hasPermission';
import AddressForm from 'components/ThemeComponents/addressForm';
// import InputMask from 'react-input-mask';
import { Link, withRouter } from 'react-router-dom';
import { getBrands } from "redux/actions/account-action";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getItems } from 'redux/actions/item-actions';
import InventoryForm from "components/Admin/Inventory/InventoryForm";
import MyImage from 'components/Upload/MyImage';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { getInvoicingSettings } from "redux/actions/invoicing-actions";
import PhoneNumber from "components/ThemeComponents/phoneNumber";
// import 'react-big-calendar/lib/sass/styles';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD
import { SC } from 'helper/ServerCall';
import AgencyDocuments from "./AgencyDocuments";
import CustomPhoneNumber from "../../ThemeComponents/CustomPhoneNumber";
import CKEditor from "react-ckeditor-component";
import { confirmAlert } from 'react-confirm-alert';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';



var validUrl = require('valid-url');
var Modal = require('react-bootstrap-modal');
var cloneDeep = require('lodash.clonedeep');

/*
    ***Read***
    For validation focus its mandotary to give "id_Name" to parent div of input field with
    col-md-xyz, with validatorAgency('id_Name','','').
    Otherwise it will generate an following error:
    js:22 Uncaught TypeError: Cannot read property 'top' of undefined.
*/
class AgencyFormPage extends Component {
    constructor(props) {
        super(props);
        this.props.clearSettingsCall();
        this.validatorAgency = new SimpleReactValidator({
            // messages: {
            // 	regex: 'website name must be as e.g https//:www.xyz.com'
            // },
            autoForceUpdate: this,
            locale: 'en'
        });
        this.state = {
            collapseAccountingContact: this.props.formType === 'add' ? true : false,
            collapseWebMasterContact: false,
            acceptsWeTravel: false,
            collapseDocument: false,
            collapseAgencySettings: this.props.formType === 'add' ? true : false,
            scroll: 0,
            agency: { ...this.props.agencyData },
            agency_id: this.props.agency_id,
            formType: this.props.formType,
            options: {
                statesprimary_address: [],
                statessecondary_address: [],
            },
            submitAgencyDocuments: false,
            copyPrimaryToAcc: false,
            submitted: false,
            editAgencyInvModal: false,
            editInvFormType: null,
            currentInv: null,
            // zipReq:true,
            primary_address_zipReq: true,
            secondary_address_zipReq: false,
            urlPrefix: 'https://',
            is_france: false,
            initial_country_France: false,
            agency_pa_is_france: false,
            agency_sa_is_france: false,
            pa_initial_country_France: false,
            sa_initial_country_France: false,
            tax_exempt: false,
            show_tax_exempt: false,
            is_ttk: false,
        };


        // this.showModal = this.showModal.bind(this);
        this.handleAgency = this.handleAgency.bind(this);
        this.handleAgencyPrimaryUser = this.handleAgencyPrimaryUser.bind(this);
        this.handleChangeAgencyDropDown = this.handleChangeAgencyDropDown.bind(this);
        this.handleAgencySettingsInput = this.handleAgencySettingsInput.bind(this);
        this.handleAgencySubmit = this.handleAgencySubmit.bind(this);
        this.handleContactInformation = this.handleContactInformation.bind(this);
        this.copyPrimaryToAcc = this.copyPrimaryToAcc.bind(this);
        this.clearState = this.clearState.bind(this);
        this.updateNCTaxCheckBox = this.updateNCTaxCheckBox.bind(this);
        this.updateZipReq = this.updateZipReq.bind(this);
        this.agencySettingCheckBox = this.agencySettingCheckBox.bind(this);
        this.handleChangeAgencySettingDropDown = this.handleChangeAgencySettingDropDown.bind(this);

    }
    updateZipReq(status, type) {
        this.setState({ [type + '_zipReq']: status });
    }
    updateNCTaxCheckBox(status, type) {
        if (this.state.formType === 'add' && type === 'primary_address') {
            const agencyCheckBoxTemp = { ...this.state.agency };
            agencyCheckBoxTemp['settings']['collect_tax']['value'] = status;
            this.setState({ agency: agencyCheckBoxTemp });
        }
    }
    handleAgency(e) {
        const { name, value } = e.target;
        const { urlPrefix } = this.state;
        const agencyState = { ...this.state.agency };
        agencyState[name] = name === 'website' ? urlPrefix + value.substr(urlPrefix.length) : value;
        this.setState({ agency: agencyState });
    }
    handleAgencyPrimaryUser(e) {
        const { name, value } = e.target;

        const agencyState = cloneDeep(this.state.agency.primary_contacts);
        agencyState[name] = value;
        this.setState({ agency: { ...this.state.agency, primary_contacts: agencyState } });
    }
    handleChangeAgencyDropDown(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
            const agencyDropDown = { ...this.state.agency };
            agencyDropDown[info['name']] = selectedOptionType['value'];
            this.setState({ agency: agencyDropDown });
        }
        if (selectedOptionType['label'] === 'TTK') {
            this.setState({ is_ttk: true });
        } else {
            this.setState({ is_ttk: false });
        }
    }

    handleChangeAgencySettingDropDown(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
            this.setState(prevState => ({
                ...prevState, agency: {
                    ...prevState.agency, settings: {
                        ...prevState.agency.settings, [info['name']]: {
                            value: selectedOptionType['value']
                        }
                    }
                }
            }));
        }
    }

    handleChangeAgencySettingInput(e) {
        const { name, value } = e.target;
        // if (info['action'] === 'select-option') {
        this.setState(prevState => ({
            ...prevState, agency: {
                ...prevState.agency, settings: {
                    ...prevState.agency.settings, [name]: {
                        value: value
                    }
                }
            }
        }));
        // }
    }

    editWeTravelSetting(data) {
        return SC.postCall('editWeTravelSetting', data);
    }

    handleOptions = (name, value) => {
        let tempValue = !value;
        tempValue = tempValue ? 1 : 0;

        this.editWeTravelSetting({ key: name, value: tempValue, agency_id: this.state.agency.id });

        this.setState(prevState => ({
            ...prevState, agency: {
                ...prevState.agency, settings: {
                    ...prevState.agency.settings, [name]: {
                        value: tempValue
                    }
                },
                // [name]: tempValue
            }
        })
            ,
            () => {
                if (name === 'wt_show_on_all_orders' || name === 'wt_option_at_order_placement') {
                    var WtOrder = 'wt_show_on_all_orders';
                    if (name === WtOrder)
                        WtOrder = 'wt_option_at_order_placement';

                    this.setState(prevState => ({
                        ...prevState, agency: {
                            ...prevState.agency, settings: {
                                ...prevState.agency.settings, [WtOrder]: {
                                    value: 0
                                }
                            }
                        }
                    }));
                }
                else if (name === 'accepts_wt_payments' && tempValue == 0) {
                    this.setState(prevState => ({
                        ...prevState, agency: {
                            ...prevState.agency, settings: {
                                ...prevState.agency.settings, ['wt_show_on_all_orders']: {
                                    value: 0
                                },
                                ['wt_option_at_order_placement']: {
                                    value: 0
                                }
                            }
                        }
                    }));
                }
            });
    }

    handleAgencyLink = (name, value) => {
        this.setState(prevState => ({
            ...prevState, agency: {
                ...prevState.agency, settings: {
                    ...prevState.agency.settings, [name]: {
                        value: value
                    }
                },
                // [name]: tempValue
            }
        }))
    }

    handleAcceptsWeTravelInput = (name, value) => {
        // const { name, value, type } = e.target;
        // const tempValue = type === 'checkbox' || 'radio' ? e.target.checked ? "1" : "0" : value;
        let tempValue = !value;
        tempValue = tempValue ? "1" : "0";
        this.setState(prevState => ({
            ...prevState, agency: {
                ...prevState.agency, settings: {
                    ...prevState.agency.settings, [name]: {
                        value: tempValue
                    }
                }
            }
        }))

    }
    handleOptionAtOrderPlacement = (name, value) => {

        // const { name, value, type } = e.target;
        let tempValue = !value;
        tempValue = tempValue ? "1" : "0";
        this.setState(prevState => ({
            ...prevState, agency: {
                ...prevState.agency, settings: {
                    ...prevState.agency.settings, [name]: {
                        value: tempValue
                    }
                }
            }
        })
            ,
            () => {
                if (name === 'wt_show_on_all_orders' || name === 'wt_option_at_order_placement') {
                    var WtOrder = '';
                    if (name === 'wt_show_on_all_orders')
                        WtOrder = 'wt_option_at_order_placement';
                    else
                        WtOrder = 'wt_show_on_all_orders';
                    this.setState(prevState => ({
                        ...prevState, agency: {
                            ...prevState.agency, settings: {
                                ...prevState.agency.settings, [WtOrder]: {
                                    value: "0"
                                }
                            }
                        }
                    }));
                }
            });
    }




    handleAgencySettingsInput(e) {
        const { name, value, type } = e.target;
        const tempValue = type === 'checkbox' || 'radio' ? e.target.checked ? 1 : 0 : value;
        this.setState(prevState => ({
            ...prevState, agency: {
                ...prevState.agency, settings: {
                    ...prevState.agency.settings, [name]: {
                        value: tempValue
                    }
                }
            }
        }), () => {
            if (name === 'no_gl_copy' || name === 'send_gl_fd_copy') {
                var TempDigital = '';
                if (name === 'no_gl_copy')
                    TempDigital = 'send_gl_fd_copy';
                else
                    TempDigital = 'no_gl_copy';
                this.setState(prevState => ({
                    ...prevState, agency: {
                        ...prevState.agency, settings: {
                            ...prevState.agency.settings, [TempDigital]: {
                                value: 0
                            }
                        }
                    }
                }));
            }
        });
    }
    handleAgencySubmit(e) {
        e.preventDefault();
        if (this.validatorAgency.allValid()) {
            this.setState({ submitted: true }, function () {
                if (this.state.formType === 'add') {
                    this.props.storeAgency(
                        {
                            ...this.state.agency,
                            'primary_address': this.ctrl_PA.returnAddressData(),
                            'secondary_address': this.ctrl_SA.returnAddressData()
                        }
                    );
                }
                else if (this.state.formType === 'edit') {
                    this.props.updateAgency(
                        {
                            ...this.state.agency,
                            'primary_address': this.ctrl_PA.returnAddressData(),
                            'secondary_address': this.ctrl_SA.returnAddressData()
                        }, this.props.agency_id).then(
                            res => {
                                //this.props.history.push('/Admin/agencies');
                            }
                        );
                }
            });
        } else {
            if (
                !this.validatorAgency.fieldValid('AccountingContactInformationName') ||
                !this.validatorAgency.fieldValid('AccountingContactInformationPhone') ||
                !this.validatorAgency.fieldValid('AccountingContactInformationEmail')
            ) {
                this.setState({ collapseAccountingContact: true });
            }
            this.setState({ submitted: false });
            focusInput(this.validatorAgency.getErrorMessages());
            this.validatorAgency.showMessages();
            this.forceUpdate();
        }
    }
    handleContactInformation(e, type) {
        const { name, value } = e.target;
        const agencyState = cloneDeep(this.state.agency[type]);
        agencyState[name] = value;
        this.setState({ agency: { ...this.state.agency, [type]: agencyState } });
    }
    copyPrimaryToAcc() {
        this.setState({ copyPrimaryToAcc: !this.state.copyPrimaryToAcc }, function () {
            if (this.state.copyPrimaryToAcc) {
                const agencyState = { ...this.state.agency };
                this.setState(prevState => ({
                    ...prevState, agency: {
                        ...prevState.agency, accounting_contacts: {
                            name: agencyState.primary_contacts.name,
                            phone1: agencyState.primary_contacts.phone1,
                            email: agencyState.primary_contacts.email,
                        }
                    }
                }));
            } else {
                this.setState(prevState => ({
                    ...prevState, agency: {
                        ...prevState.agency, accounting_contacts: {
                            name: '',
                            phone1: '',
                            email: '',
                        }
                    }
                }));
            }
        });
    }
    clearState(type) {
        this.setState(prevState => ({
            ...prevState, options: {
                ...prevState.options, ['states' + type]: []
            }
        }));
    }
    setEditFormType(editFormType, inventoryItem) {
        this.setState({ editInvFormType: editFormType, currentInv: inventoryItem }, function () {
            this.setState({ editAgencyInvModal: true });
        });
    }
    handleInventorySubmit(InvData, formType) {
        const { agency_id } = this.state;
        if (formType === 'edit') {
            const { editInvFormType } = this.state;
            InvData = { ...InvData, agency_id: agency_id };
            this.props.updateAgencyInventoryCall(InvData, InvData.id, editInvFormType).then(
                response => {
                    this.setState({
                        editAgencyInvModal: false
                    });
                }
            );
        }
    }
    handleAgencyInv(e, InvType) {
        const { name, value } = e.target;
        const agencyStateInv = cloneDeep(this.state.agency.Inventories);
        agencyStateInv[InvType][name] = value;
        this.setState({ agency: { ...this.state.agency, Inventories: agencyStateInv } });
    }
    setAddressStates(type, statesData) {
        this.setState(prevState => ({
            ...prevState, options: {
                ...prevState.options, ['states' + type]: statesData
            }
        }));
    }

    handleTaxExampt = (value) => {
		let tempValue = !value;
        tempValue = tempValue ? 1 : 0;
		const agency = { ...this.state.agency };
		agency.tax_exempt = tempValue;
		this.setState({
            agency: agency
        });

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.agencyData !== this.props.agencyData) {
            this.setState({ agency: {...this.props.agencyData}});
        }
        if (prevState.agency.settings.send_gl_fd_copy.value !== this.state.agency.settings.send_gl_fd_copy.value) {
            // if (this.state.agency.settings.send_gl_fd_copy.value ) {
            const agencyCheckBoxTemp = { ...this.state.agency };
            agencyCheckBoxTemp['settings']['free_fash_drive_to_client']['value'] = this.state.agency.settings.send_gl_fd_copy.value;
            this.setState({ agency: agencyCheckBoxTemp });
            // }
        }
        if ((prevState.is_france !== this.state.is_france) || (prevState.initial_country_France !== this.state.initial_country_France) || (prevState.pa_initial_country_France !== this.state.pa_initial_country_France) || (prevState.sa_initial_country_France !== this.state.sa_initial_country_France) || (prevState.agency_pa_is_france !== this.state.agency_pa_is_france) || (prevState.agency_sa_is_france !== this.state.agency_sa_is_france))  {
            const tempValue = { ...this.state.agency };
            tempValue['primary_contacts']['phone1'] = this.state.agency.primary_contacts.phone1;
            tempValue['accounting_contacts']['phone1'] = this.state.agency.accounting_contacts.phone1;
            this.setState({
                agency: tempValue
            }, () => {
                let event = new MouseEvent('click', {
                    'bubbles': true,
                    'cancelable': false
                })
                var node1 = document.getElementById('france_1');
                var node2 = document.getElementById('france_2');
                node1.dispatchEvent(event);
                setTimeout(() => {
                    node2.dispatchEvent(event);
                }, 0);
            });

        }
        if (prevProps.updatedInv !== this.props.updatedInv) {
            this.setState(prevState => ({
                ...prevState, agency: {
                    ...prevState.agency, Inventories: {
                        ...prevState.agency.Inventories, [this.props.updatedInv.itemParent]: this.props.updatedInv
                    }
                }
            }));
        }

        if (this.props.defaultBrandByName && prevProps.brands !== this.props.brands) {
            this.setState({
                agency: {
                    ...this.state.agency,
                    brand_id: this.props.defaultBrandByName ? returnColumnValueWithColumnMatch(this.props.brands, 'value', [OC.DEFAULTBRAND], 'label') : null,


                }
            });
        }
        // if (this.state.is_france === false) {
        //     this.setState({ initial_country_France: false})
        // }
        // console.log("agency", this.state.agency);
        if (this.state.agency && this.state.agency.accounts && this.state.agency.accounts[0] && this.state.agency.accounts[0].title === 'Travel TripKit') {
            console.log("condition truee");
            if (this.state.is_ttk !== true) {
                console.log("this.state.is_ttk !== true");
                this.setState({
                    is_ttk: true
                }, ()=>{console.log("state updated");})
            }
        } else {
            console.log("condition false");
        }
    }


    componentDidMount() {

        this.props.getBrandsCall();
        if (this.state.formType === 'edit') {
            this.props.getItemsCall('Products');
            this.props.getAgenciesCall();
            this.setEditFormType = this.setEditFormType.bind(this);
            this.handleInventorySubmit = this.handleInventorySubmit.bind(this);
            this.handleAgencyInv = this.handleAgencyInv.bind(this);
        }
        this.props.getAgencyTypeCall();
        this.props.getPVSalesRepCall();
        this.props.getCountriesCall();
        this.props.getProgramTypeCall();
        if (this.state.formType === 'add') {
            this.props.getInvoicePeriodCall().then(response => {
                const agencyCheckBoxTemp = { ...this.state.agency };
                agencyCheckBoxTemp['settings']['invoice_period']['value'] = response.data.value;
                this.setState({ agency: agencyCheckBoxTemp });
            });
        }
        if (this.state.agency) {

            const agency= { ...this.state.agency}
            console.log("agency", agency);
            if (agency && agency.primary_address && agency.primary_address.state && agency.primary_address.state.title && agency.primary_address.state.title ==="North Carolina"){
                console.log("condition truee");
                this.setState({
                    show_tax_exempt:true
                })
            } else {
                this.setState({
                    show_tax_exempt:false
                })
            }
        }


        const { defaultBrandByName } = this.props;
        if (defaultBrandByName) {
            const { brands } = this.props;
            this.setState({
                agency: {
                    ...this.state.agency,
                    brand_id: defaultBrandByName ? returnColumnValueWithColumnMatch(brands, 'value', [OC.DEFAULTBRAND], 'label') : null,

                }
            });
        }

        if (this.props.primary_address && this.props.primary_address.country && this.props.primary_address.country.title === 'France') {
            this.setState({
                pa_initial_country_France: true,
                initial_country_France: true,
            }
                //     ,() =>{
                //     this.setState({
                //         initial_country_France: false
                //     })
                // }
            )
        }
        if (this.props.secondary_address && this.props.secondary_address.country && this.props.secondary_address.country.title && this.props.secondary_address.country.title === 'France') {
            this.setState({
                sa_initial_country_France: true
            }
                //     ,() =>{
                //     this.setState({
                //         initial_country_France: false
                //     })
                // }
            )
        }

    }
    agencySettingCheckBox(label, name, tooltip, divWidth) {
        return (
            <div className={"col-md-" + divWidth}>
                <div className='form-group'>
                    <label className="container-check">{label}
                        <input type="checkbox" name={name} className="checkbox" value={this.state.agency.settings[name] ? parseInt(this.state.agency.settings[name]['value'], 10) ? true : false : false} onChange={this.handleAgencySettingsInput} checked={this.state.agency.settings[name] ? parseInt(this.state.agency.settings[name]['value'], 0) : 0} />
                        <span className="checkmark"></span>
                    </label>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
                        <span className="question_mark">?</span>
                    </OverlayTrigger>
                </div>
            </div>
        );
    }
    agencySettingCheckBoxReverse(label, name, tooltip, divWidth) {
        return (
            <div className={"col-md-" + divWidth}>
                <div className='form-group'>
                    <label className="container-check">{label}
                        <input type="checkbox" name={name} className="checkbox" value={this.state.agency.settings[name] ? parseInt(this.state.agency.settings[name]['value'], 10) ? false : true : true} onChange={this.handleAgencySettingsInput} checked={this.state.agency.settings[name] ? parseInt(this.state.agency.settings[name]['value'], 0) : 0} />
                        <span className="checkmark"></span>
                    </label>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
                        <span className="question_mark">?</span>
                    </OverlayTrigger>
                </div>
            </div>
        );
    }

    getChildState = (childState) => {
        this.setState({
            is_france: childState
        }
        // , () => {
        //     let event = new MouseEvent('click', {
        //         'bubbles': true,
        //         'cancelable': false
        //     })
        //     var node1 = document.getElementById('france_1');
        //     var node2 = document.getElementById('france_2');
        //     // console.log("🚀 ~ file: AgencyForm.jsx ~ line 580 ~ AgencyFormPage ~ node1", node1)
        //     // console.log("🚀 ~ file: AgencyForm.jsx ~ line 581 ~ AgencyFormPage ~ node2", node2)
        //     node1.dispatchEvent(event);
        //     setTimeout(() => {
        //         node2.dispatchEvent(event);
        //     }, 0);
        // }
        )

    }

    setStateOfParent = (newValue) => {
        this.setState({ initial_country_France: newValue });
    }
    setStateOfParentPa = (newValue) => {
        this.setState({
            pa_initial_country_France: newValue
            });
    }
    setStateOfParentSa = (newValue) => {
        this.setState({
            sa_initial_country_France: newValue
            });
    }

    getAgencyPaChildState = (state) =>{
        this.setState({
            agency_pa_is_france: state
        })
    }

    getAgencySaChildState = ( state ) => {
        this.setState({
            agency_sa_is_france : state
        })
    }

    setStateOfParentNCS = (newValue) => {
        this.setState({ show_tax_exempt: newValue });
    }

    onChange = (key) => (evt) => {
		console.log("1: ", key);
		// console.log("onChange fired with event info: ", evt);
		var newContent = evt.editor.getData();
		this.setState({ agency: { ...this.state.agency, [key]: newContent } })
		// this.setState({
		//  	content: newContent
		// })
	}

    deleteConfirmAgency(original) {
        confirmAlert({
        customUI: ({ onClose }) => {
            return (
            <Confirmalertfordelete typeName="Agency" agencyAction={original.is_active ? " Inactivate" : "Activate"} description={`Are you sure you want to ${original.is_active? " Inactivate" : "Activate"} the agency`} onClosePro={onClose} deleteType={() => this.deleteAgency(original.id)} />
            )
        }
        })
    }

    deleteAgency(id) {
        this.props.deleteAgencyCall(id).then(Response => {
            this.props.getAgencyCall(id);
        });
    }
    render() {
        this.validatorAgency.purgeFields();
        const {
            agency,
            copyPrimaryToAcc,
            formType,
            currentInv,
            editInvFormType,
            primary_address_zipReq,
            secondary_address_zipReq,
            agency_id,
            is_france,
            initial_country_France,
            sa_initial_country_France,
            pa_initial_country_France,
            agency_pa_is_france,
            agency_sa_is_france,
            show_tax_exempt,
            is_ttk
        } = this.state;
        const {
            agency_type_id,
            salesRep,
            programType,
            countries,
            items,
            columns,
            agenciesOption,
            primary_address,
            agn
        } = this.props;
        const brands = this.props.brands.filter(item => {
            return Number(item.is_master) !== 1;
        });
        let { accepts_wt_payments } = this.state;
        return (
            <div className="content" id="add_agency">
                <form onSubmit={this.handleAgencySubmit} id="agency-form">
                    <div className="row">
                        <Card title="Primary information" bsClass={["innerCard"]} content={
                            <React.Fragment>
                                <div className="clearfix">
                                    <span></span>
                                    <div className="col-md-3" id="AgencyName">

                                        <div className='form-group'>
                                            <div className="clearfix">
                                                <span></span>
                                                <div className="col-md-12 lbl_container">
                                                    <label htmlFor="name">Agency Name<span className="requiredClass">*</span></label>
                                                </div>

                                                <div className="col-md-12">
                                                    <input autoFocus type="text" name="name" className="form-control" value={agency.name} onChange={this.handleAgency} />
                                                    {this.validatorAgency.message('AgencyName', agency.name, 'required')}
                                                </div>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <React.Fragment>
                                        <div className="col-md-3" id="primaryUserName">
                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <span></span>
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">Primary Contact Name<span className="requiredClass">*</span></label>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <input type="text" name="name" className="form-control" value={agency.primary_contacts.name} onChange={this.handleAgencyPrimaryUser} />
                                                        {this.validatorAgency.message('primaryUserName', agency.primary_contacts.name, 'required')}
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3" id="primaryUserContact">
                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <span></span>
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">Primary Contact Number<span className="requiredClass">*</span></label>
                                                    </div>

                                                    <div className="col-md-12">
                                                        {/* {
                                                            // is_france || (primary_address && primary_address.country && primary_address.country.title === 'France')
                                                            is_france || initial_country_France

                                                            ? */}

                                                        <CustomPhoneNumber
                                                            value={agency.primary_contacts.phone1}
                                                            handleParent={this.handleAgencyPrimaryUser}
                                                            validationName={'primaryUserContact'}
                                                            validator={this.validatorAgency}
                                                            required={true}
                                                            elemId="france_1"
                                                            name={'phone1'}
                                                            is_france={is_france}
                                                            initial_country_France={initial_country_France}
                                                            pa_initial_country_France={pa_initial_country_France}
                                                            sa_initial_country_France={sa_initial_country_France}
                                                            agency_pa_is_france={agency_pa_is_france}
                                                            agency_sa_is_france={agency_sa_is_france}
                                                        />
                                                        {/* :
                                                                <PhoneNumber
                                                                    value={agency.primary_contacts.phone1}
                                                                    handleParent={this.handleAgencyPrimaryUser}
                                                                    validationName={'primaryUserContact'}
                                                                    validator={this.validatorAgency}
                                                                    required={true}
                                                                    name={'phone1'}
                                                                />
                                                        } */}
                                                        {
                                                            /*
                                                                phone number updated
                                                                <InputMask
                                                                    mask={"999-999-9999-999999"}
                                                                    maskChar={null}
                                                                    type="text"
                                                                    name="phone1"
                                                                    className="form-control phone_icon"
                                                                    placeholder="123-456-7890-123456"
                                                                    value={agency.primary_contacts.phone1}
                                                                    onChange={this.handleAgencyPrimaryUser} />
                                                                {
                                                                    this.validatorAgency.message('primaryUserContact', agency.primary_contacts.phone1, [
                                                                    'required',
                                                                    { 'regex': [/^[\d]{3}[-]?[\d]{3}[-]?[\d]{4}[-]?[\d]{0,6}$/] }
                                                                ])
                                                                }

                                                            */
                                                        }
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3" id="primaryUserEmail">
                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <span></span>
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">Primary Contact Email<span className="requiredClass">*</span></label>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <input type="text" name="email" className="form-control" value={agency.primary_contacts.email} onChange={this.handleAgencyPrimaryUser} />
                                                        {this.validatorAgency.message('primaryUserEmail', agency.primary_contacts.email, 'required|email')}
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>


                                    <div className="col-md-3" id="PublishableAgencyName">
                                        <div className='form-group'>
                                            <div className="clearfix">
                                                <span></span>
                                                <div className="col-md-12 lbl_container">
                                                    <label htmlFor="name">Publishable Agency Name</label>
                                                </div>
                                                <div className="col-md-12">
                                                    <input autoFocus type="text" name="publishable_agency_name" className="form-control" value={agency.publishable_agency_name} onChange={this.handleAgency} />
                                                </div>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3" id="website">
                                        <div className='form-group'>
                                            <div className="clearfix">
                                                <div className="col-md-12 lbl_container">
                                                    <label htmlFor="name">
                                                        Website
                                                    </label>
                                                    {
                                                        (validUrl.isUri(agency.website) && validUrl.is_https_uri(agency.website)) &&
                                                        <a rel="noopener noreferrer" target="_blank" className="blue_link target_blank pull-right" style={{ 'fontSize': '12px' }} href={agency.website}> Go</a>
                                                    }
                                                </div>

                                                <div className="col-md-12">
                                                    <input type="text" name="website" className="form-control" value={agency.website} onChange={this.handleAgency} placeholder={'www.xyz.com'} />
                                                    {

                                                        /*this.validatorAgency.message('website', agency.website, ['regex:^https://www[a-z0-9]+(?:[-.][a-z0-9]+)*[a-z]{2,5}(?::[0-9]{1,5})?(?:/.*)?$', 'url'] ) */
                                                        agency.website.match(/^https:\/\/\w/) ?
                                                            this.validatorAgency.message('website', agency.website, ['url'])
                                                            : ''
                                                    }
                                                </div>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3" id="agency_type_id">
                                        <div className='form-group'>
                                            <div className="clearfix">
                                                <div className="col-md-12 lbl_container">
                                                    <label htmlFor="name">Agency Type<span className="requiredClass">*</span> </label>
                                                </div>

                                                <div className="col-md-12">
                                                    <Select
                                                        value={returnLabelWithValue(agency.agency_type_id, agency_type_id)}
                                                        onChange={this.handleChangeAgencyDropDown}
                                                        options={agency_type_id}
                                                        isSearchable={true}
                                                        isMulti={false}
                                                        name='agency_type_id'
                                                        placeholder={'Select Agency Type'}

                                                    />
                                                    {this.validatorAgency.message('agency_type_id', agency.agency_type_id, 'required')}
                                                </div>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3" id="ProgramType">
                                        <div className='form-group'>
                                            <div className="clearfix">
                                                <div className="col-md-12 lbl_container">
                                                    <label htmlFor="name">Program Type<span className="requiredClass">*</span></label>
                                                </div>
                                                <div className="col-md-12" >
                                                    <Select
                                                        value={returnLabelWithValueGroup(agency.program_type_id, programType)}
                                                        onChange={this.handleChangeAgencyDropDown}
                                                        options={programType}
                                                        isSearchable={true}
                                                        isMulti={false}
                                                        name='program_type_id'
                                                        placeholder={'Select Program Type'}
                                                    />
                                                    {this.validatorAgency.message('ProgramType', agency.program_type_id, 'required')}
                                                </div>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3" id="sales_rep_id">
                                        <div className='form-group'>
                                            <div className="clearfix">
                                                <div className="col-md-12 lbl_container">
                                                    <label htmlFor="name">GTV Sales Rep<span className="requiredClass">*</span> </label>
                                                </div>

                                                <div className="col-md-12">
                                                    <Select
                                                        value={returnLabelWithValue(agency.sales_rep_id, salesRep)}
                                                        onChange={this.handleChangeAgencyDropDown}
                                                        options={salesRep}
                                                        isSearchable={true}
                                                        name='sales_rep_id'
                                                        isMulti={false}
                                                        placeholder={'Select GTV Sales Rep'}
                                                    />
                                                    {this.validatorAgency.message('sales_rep_id', agency.sales_rep_id, 'required')}
                                                </div>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        formType === 'add'
                                            ?
                                            <React.Fragment>
                                                <div className="col-md-3" id="Brand">
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <div className="col-md-12 lbl_container">
                                                                <label htmlFor="name">Brand<span className="requiredClass">*</span></label>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <Select
                                                                    value={returnLabelWithValue(agency.brand_id, brands)}
                                                                    onChange={this.handleChangeAgencyDropDown}
                                                                    options={brands}
                                                                    isSearchable={true}
                                                                    isMulti={false}
                                                                    name='brand_id'
                                                                    placeholder={'Select Brand'}
                                                                />
                                                                {this.validatorAgency.message('Brand', agency.brand_id, 'required')}
                                                            </div>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3" id="primaryUserName">
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <span></span>
                                                            <div className="col-md-12 lbl_container">
                                                                <label htmlFor="name">Username<span className="requiredClass">*</span></label>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <input type="text" name="username" className="form-control" value={agency.primary_contacts.username} onChange={this.handleAgencyPrimaryUser} />
                                                                {this.validatorAgency.message('primaryUserName', agency.primary_contacts.username, 'required|min:5')}
                                                            </div>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3" id="password">
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <span></span>
                                                            <div className="col-md-12 lbl_container">
                                                                <label htmlFor="name">Password<span className="requiredClass">*</span></label>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <input type="password" name="password" className="form-control" value={agency.primary_contacts.password} onChange={this.handleAgencyPrimaryUser} />
                                                                {this.validatorAgency.message('password', agency.primary_contacts.password, 'required|min:5')}
                                                            </div>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    /*
                                                    <div className="col-md-3" id="confirmPassword">
                                                        <div className='form-group'>
                                                            <div className="clearfix">
                                                                <span></span>
                                                                <div className="col-md-12 lbl_container">
                                                                    <label htmlFor="name">Confirm Password<span className="requiredClass">*</span></label>
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <input type="password" name="confirm_password" className="form-control" value={agency.primary_contacts.confirm_password} onChange={this.handleAgencyPrimaryUser} />
                                                                    {this.validatorAgency.message('confirmPassword', agency.primary_contacts.confirm_password, 'required')}
                                                                </div>
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    */
                                                }
                                                <span></span>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <div className="col-md-3" id="Brands">
                                                    <div className='form-group'>
                                                        <div className="col-md-12 lbl_container">
                                                            <label htmlFor="name">Brand<span className="requiredClass">*</span></label>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <input readOnly type='input' className='form-control' value={agency.accounts.length >= 1 ? agency.accounts[0].title : null} />
                                                        </div>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <span></span>
                                            </React.Fragment>
                                    }

                                       
                                    {
                                        !is_ttk &&
                                        <div className="col-md-3">
                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <div className="col-md-12 col-md-6 lbl_container">
                                                        <label htmlFor="name">Digital Only</label>
                                                    </div>
                                                    <div className="col-md-12 col-sm-6 col-xs-6">
                                                        <div >
                                                            <label style={{ 'marginBottom': '0px' }} ><input onChange={this.handleAgencySettingsInput} type='checkbox' name='no_gl_copy' checked={agency.settings.no_gl_copy ? parseInt(agency.settings.no_gl_copy.value, 0) ? true : false : false} />NO DVDs for travelers, No GL Copy</label>
                                                        </div>
                                                        <div >
                                                            <label style={{ 'marginBottom': '0px' }}><input onChange={this.handleAgencySettingsInput} type='checkbox' name='send_gl_fd_copy' checked={agency.settings.send_gl_fd_copy ? parseInt(agency.settings.send_gl_fd_copy.value, 0) ? true : false : false} />NO DVDs for travelers, send GL FD Copy</label>
                                                        </div>
                                                        {/*<label className="container-check mt-xs"><input  type="checkbox" name="no_gl_copy" className="checkbox" value={agency.settings.no_gl_copy ? parseInt(agency.settings.no_gl_copy.value, 0) ? true : false : false} onChange={this.handleAgencySettingsInput} checked={agency.settings.no_gl_copy ? parseInt(agency.settings.no_gl_copy.value, 10) : 0} /><span className="checkmark"></span></label>*/}
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {

                                        'primary_logo' in agency.agency_resources &&
                                        <div className="col-md-3">
                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">Logo</label>
                                                    </div>
                                                    <MyImage src={agency.agency_resources.primary_logo.file_path}  height={"50px"} />
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                        <div className="col-md-12" id="activeAgency">
                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <span></span>
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">{this.state.agency.is_active ? "Inactivate " : "Activate "} Agency</label>
                                                    </div>
                                                    <div className="col-md-12">
                                                    <input type="checkbox"
                                                        onChange={() => this.deleteConfirmAgency(this.state.agency)}
                                                        id="switchgridView" checked={this.state.agency.is_active ? 'checked' : ''}
                                                        className="cssSwitch mr-md"/>
                                                    <label className="cssSwitch-sm" htmlFor="switchgridView">Toggle</label>
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {/* <div className="col-md-3">
                                                <div className="">
                                                    {this.state.agency.is_active ? "Inactivate " : "Activate "} Agency
                                                </div>
                                                <div className="">
                                                    <input type="checkbox"
                                                        onChange={() => this.deleteConfirmAgency(this.state.agency)}
                                                        id="switchgridView" checked={this.state.agency.is_active ? 'checked' : ''}
                                                        className="cssSwitch mr-md"/>
                                                    <label className="cssSwitch-sm" htmlFor="switchgridView">Toggle</label>
                                                </div>
                                        </div> */}


                                    {
                                        /*
                                        <div className="col-md-3" id="sales_rep_id">
                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">Digital Only-NO DVDs for travelers, send GL FD Copy</label>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <label className="container-check mt-xs"><input  type="checkbox" name="send_gl_fd_copy" className="checkbox" value={agency.settings.send_gl_fd_copy ? parseInt(agency.settings.send_gl_fd_copy.value, 0) ? true : false : false} onChange={this.handleAgencySettingsInput} checked={agency.settings.send_gl_fd_copy ? parseInt(agency.settings.send_gl_fd_copy.value, 10) : 0} /><span className="checkmark"></span></label>
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                        */
                                    }
                                </div>
                                {
                                    formType === 'edit' &&
                                    <div className="clearfix">
                                        {
                                            agency.created_at &&
                                            <div className="col-md-12 text-right" style={{ 'fontSize': "10px" }}>
                                                Created Date : {agency.created_at}
                                            </div>
                                        }
                                        <div className="col-md-12 text-right">
                                            <CopyToClipboard text={
                                                agency.name + '\n' +
                                                agency.primary_address.street_address_1 + '\n' +
                                                agency.primary_address.city + ', ' +
                                                agency.primary_address.state.title + ' ' +
                                                agency.primary_address.zipcode ? agency.primary_address.zipcode : ''
                                            }
                                            >
                                                {
                                                    /*
                                                    <CopyToClipboard text={
                                                            'Agency Name ='+agency.name+
                                                            ', primary street ='+agency.primary_address.street_address_1
                                                            +', primary city ='+agency.primary_address.city
                                                            +', primary state ='+agency.primary_address.state.title
                                                            +', primary zip ='+agency.primary_address.zipcode
                                                            +', accounting name='+agency.accounting_contacts.name
                                                            +', accounting phone='+agency.accounting_contacts.phone1
                                                        }
                                                    >
                                                    */
                                                }

                                                <button className="orderDetailsAction copyColorAction" type="button">
                                                    <OverlayTrigger placement="top" overlay={
                                                        <Tooltip id="tooltip">
                                                            Agency name, Primary address, Accounting contact
                                                        </Tooltip>
                                                    }>
                                                        <span className="question_mark ml-none">?</span>
                                                    </OverlayTrigger>
                                                </button>
                                            </CopyToClipboard>
                                            <Link to={'/Admin/' + agency_id + '/agency-resource'} className="" style={{ color: '#2F4576' }}><i className="fa fa-file" />
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id="tooltip">
                                                        Agency Resources
                                                    </Tooltip>
                                                }>
                                                    <span className="question_mark ml-none">?</span>
                                                </OverlayTrigger>
                                            </Link>
                                            <Link to={'/Admin/' + agency_id + '/agency-users'} className="" style={{ color: '#2F4576' }}><i className="fa fa-users" />
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id="tooltip">
                                                        Agency Users
                                                    </Tooltip>
                                                }>
                                                    <span className="question_mark ml-none">?</span>
                                                </OverlayTrigger>
                                            </Link>
                                        </div>

                                    </div>
                                }
                            </React.Fragment>
                        } />
                    </div>
                    <div className="row customHalf">
                        <div className="col-md-6">
                            <div className="row">
                                <Card title="Primary Address" bsClass={["innerCard"]} content={
                                    <AddressForm
                                        provideCtrl={ctrl => this.ctrl_PA = ctrl}
                                        htmlElement="PrimaryAddress"
                                        clearState={this.clearState}
                                        getCarolinaTAddressFormax={this.props.getSettingValueCall}
                                        options={
                                            {
                                                'countries': countries
                                            }
                                        }
                                        type="primary_address"
                                        adressObject={agency.primary_address}
                                        validatorInstance={this.validatorAgency}
                                        requiredField={
                                            {
                                                'street_address_1': 'required',
                                                'country_id': 'required',
                                                'city': 'required',
                                                // 'state_id': !is_france ? ['required'] : '',
                                                'state_id': !(initial_country_France || is_france) ? ['required'] : '',
                                                'zipcode': primary_address_zipReq ? ['required'] : ''
                                            }
                                        }
                                        isClearableCountryPro={false}
                                        defaultCountry={true}
                                        updateZipReq={this.updateZipReq}
                                        updateNCTaxCheckBox={this.updateNCTaxCheckBox}
                                        childState={this.getChildState}
                                        agencyPaChildState={this.getAgencyPaChildState}
                                        setStateOfParentNCS={this.setStateOfParentNCS}
                                        setStateOfParent={this.setStateOfParent}
                                        agencySetStateOfParentPa={this.setStateOfParentPa}
                                    />

                                } />
                            </div>
                        </div>

                        <div className="col-md-6 pull-right">
                            <div className="row">
                                <Card title="Secondary Address" bsClass={["innerCard"]} content={
                                    <AddressForm
                                        provideCtrl={ctrl => this.ctrl_SA = ctrl}
                                        htmlElement="SecondaryAddress"
                                        clearState={this.clearState}
                                        options={
                                            {
                                                'countries': countries
                                            }
                                        }
                                        type="secondary_address"
                                        adressObject={agency.secondary_address}
                                        validatorInstance={this.validatorAgency}
                                        requiredField={
                                            {
                                                // 'zipcode': secondary_address_zipReq ? ['required'] : '',
                                            }
                                        }
                                        isClearableCountryPro={true}
                                        defaultCountry={false}
                                        childState={this.getChildState}
                                        agencySaChildState={this.getAgencySaChildState}
                                        agencySetStateOfParentSa={this.setStateOfParentSa}
                                    />
                                } />
                            </div>
                        </div>
                    </div>
                    <div className="row customHalf">
                        <div className="col-md-6">
                            <div className="row">
                                <Card bsClass={["innerCard"]} title="Accounting Contact Information" collapseTrigger={this.state.collapseAccountingContact} collapsHandler={() => this.setState({ collapseAccountingContact: !this.state.collapseAccountingContact })} collapsable={true}
                                    content={
                                        <div className="clearfix">
                                            <div className="">
                                                <div className="col-md-6">
                                                    <div className='form-group' id="AccountingContactName">
                                                        <label htmlFor="name">Name<span className="requiredClass">*</span></label>
                                                        <input type="text" name="name" className="form-control" value={agency.accounting_contacts.name} onChange={(event) => this.handleContactInformation(event, 'accounting_contacts')} />
                                                        {this.validatorAgency.message('AccountingContactName ', agency.accounting_contacts.name, 'required')}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className='form-group' id="AccountingContactPhone">
                                                        <label htmlFor="phone1">Phone<span className="requiredClass">*</span></label>

                                                        {/* {
                                                            // is_france || (primary_address && primary_address.country && primary_address.country.title === 'France')
                                                            is_france || initial_country_France

                                                            ? */}

                                                        <CustomPhoneNumber
                                                            value={agency.accounting_contacts.phone1}
                                                            handleParent={(event) => this.handleContactInformation(event, 'accounting_contacts')}
                                                            validationName={'AccountingContactPhone'}
                                                            validator={this.validatorAgency}
                                                            elemId="france_2"
                                                            required={true}
                                                            name={'phone1'}
                                                            is_france={is_france}
                                                            initial_country_France={initial_country_France}
                                                            pa_initial_country_France={pa_initial_country_France}
                                                            sa_initial_country_France={sa_initial_country_France}
                                                            agency_pa_is_france={agency_pa_is_france}
                                                            agency_sa_is_france={agency_sa_is_france}
                                                        />
                                                        {/* :
                                                            <PhoneNumber
                                                                value={agency.accounting_contacts.phone1}
                                                                handleParent={(event) => this.handleContactInformation(event, 'accounting_contacts')}
                                                                validationName={'AccountingContactPhone'}
                                                                validator={this.validatorAgency}
                                                                required={true}
                                                                name={'phone1'}
                                                            />
                                                        } */}
                                                        {
                                                            /*phone number updated
                                                                <InputMask mask="999-999-9999" maskChar={null} type="text" name="phone1" className="form-control phone_icon" placeholder="123-456-7890" value={agency.accounting_contacts.phone1} onChange={(event) => this.handleContactInformation(event, 'accounting_contacts')} />

                                                                {this.validatorAgency.message('AccountingContactPhone', agency.accounting_contacts.phone1, ['regex:^(([0-9]{3})|[0-9]{3})[-]?[\0-9]{3}[-]?[0-9]{4}$', 'required'])}
                                                            */
                                                        }

                                                    </div>
                                                </div>
                                                <span></span>
                                            </div>


                                            <div className="">
                                                {/*
													<div className="col-md-6">
														<div className='form-group'>
															<label htmlFor="fax">Fax</label>
															<InputMask mask="999-999-9999" maskChar={null} type="text" name="fax" className="form-control fax_icon" placeholder="123-456-7890" value={agency.accounting_contacts.fax} onChange={(event) => this.handleContactInformation(event, 'accounting_contacts')} />
															<input type="text" name="fax" className="form-control fax_icon"  value={agency.accounting_contacts.fax} onChange={(event)=>this.handleContactInformation(event, 'accounting_contacts')} />
															{ this.validatorAgency.message('Accounting contact Information Fax ', agency.accounting_contacts.fax, 'required') }
														</div>
													</div>
												*/}


                                                <div className="col-md-6">
                                                    <div className='form-group' id="AccountingContactEmail">
                                                        <label htmlFor="email">Email<span className="requiredClass">*</span></label>
                                                        <input type="email" name="email" className="form-control email_icon" value={agency.accounting_contacts.email} onChange={(event) => this.handleContactInformation(event, 'accounting_contacts')} />
                                                        {this.validatorAgency.message('AccountingContactEmail ', agency.accounting_contacts.email, 'required|email')}
                                                    </div>
                                                </div>

                                                <span></span>
                                            </div>
                                            {
                                                formType === 'add' &&
                                                <div className="">
                                                    <div className='col-md-12' >
                                                        <label className="container-check">Same as Primary Contact info
                                                            <input
                                                                type="checkbox"
                                                                name="videoShip"
                                                                className="checkbox"
                                                                value={copyPrimaryToAcc}
                                                                onChange={(event) => this.copyPrimaryToAcc()}
                                                                checked={copyPrimaryToAcc}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <span></span>
                                                </div>
                                            }

                                        </div>
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-md-6 pull-right">
                            <div className="row">
                                <Card title="Web Master Contact Information" collapseTrigger={this.state.collapseWebMasterContact} collapsHandler={() => this.setState({ collapseWebMasterContact: !this.state.collapseWebMasterContact })} bsClass={["innerCard"]} collapsable={true} content={
                                    <div className="clearfix">
                                        <span></span>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label htmlFor="name">Name</label>
                                                <input type="text" name="name" className="form-control" value={agency.web_master_contacts.name} onChange={(event) => this.handleContactInformation(event, 'web_master_contacts')} />
                                                { /*this.validatorAgency.message('Accounting contact Information Name ', agency.accounting_contacts.name, 'required') */}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group' style={{ 'paddingRight': '5px' }} id="WebMasterContactInformationEmail">
                                                <label htmlFor="email">Email</label>
                                                <input type="text" name="email" className="form-control email_icon" value={agency.web_master_contacts.email} onChange={(event) => this.handleContactInformation(event, 'web_master_contacts')} />
                                                {/* {this.validatorAgency.message('WebMasterContactInformationEmail ', agency.web_master_contacts.email, 'email')} */}
                                            </div>
                                        </div>
                                    </div>
                                } />
                            </div>
                        </div>
                    </div>

                    {
                        this.state.agency_id ?
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <Card bsClass={["innerCard"]} title="Add Documents" collapseTrigger={this.state.collapseDocument} collapsHandler={() => this.setState({ collapseDocument: !this.state.collapseDocument })} collapsable={true}
                                            content={
                                                <div className="clearfix">
                                                    <AgencyDocuments
                                                        agency_id={this.state.agency_id}
                                                        formType={'edit'}
                                                    // notEditable={this.props.notEditable}
                                                    // formSubmitted={this.state.submitAgencyDocuments} id={this.state.agency_id}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    {
                        this.state.agency_id ?
                            <div className="row" >
                                <Card title="More Buttons On App" collapseTrigger={this.state.acceptsWeTravel} collapsHandler={() => this.setState({ acceptsWeTravel: !this.state.acceptsWeTravel })} bsClass={["innerCard"]} collapsable={true} content={

                                    <div>
                                        <MoreButtonsWeTravel
                                            agency_id={this.state.agency_id}
                                        />
                                        <MoreButtonsList
                                            columns={columns}
                                            agency_id={this.state.agency_id}
                                        />
                                    </div>

                                    // </div>
                                } />
                            </div>
                            : ''
                    }

                    <div className="row">
                        <Card title="Agency Settings" collapseTrigger={this.state.collapseAgencySettings} collapsHandler={() => this.setState({ collapseAgencySettings: !this.state.collapseAgencySettings })} bsClass={["innerCard"]} collapsable={true} content={
                            <div className="clearfix">
                                <div className="customHalf">
                                    <span></span>
                                    <div className="col-md-6" style={{ 'paddingLeft': '0' }}>
                                        <span></span>
                                        {
                                            !is_ttk &&
                                            <React.Fragment>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <label htmlFor="name">Free DVD To Agency</label>
                                                        <input type="number" name="free_dvd_to_agency" className="form-control" min="0" value={agency.free_dvd_to_agency} onChange={this.handleAgency} />
                                                        { /* this.validatorAgency.message('free_dvd_to_agency', agency.free_dvd_to_agency, 'integer') */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6" id="sleeveInventory">
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <div className="col-md-12 lbl_container">
                                                                <label htmlFor="name">Sleeve Inventory: </label>
                                                            </div>


                                                            <div className="col-md-12 hasInputIcons">
                                                                {
                                                                    formType === 'edit' &&
                                                                    <div className="inputIcons">
                                                                        <button type='button' className="addIcon orderDetailsAction" onClick={() => this.setEditFormType('Add', 'Sleeve')}>&nbsp;</button>
                                                                        <button type='button' disabled={agency.Inventories.Sleeve.quantity > 0 ? false : true} className="adjustIcon orderDetailsAction" onClick={() => this.setEditFormType('Adjust', 'Sleeve')}>&nbsp; </button>
                                                                    </div>
                                                                }
                                                                <input type="number" readOnly={formType === 'edit' ? true : false} name="quantity" className="form-control" value={agency.Inventories.Sleeve.quantity} onChange={(event) => this.handleAgencyInv(event, 'Sleeve')} />
                                                                <span className="text-xxs-rem text-semi-muted">Last year usage: 100</span>
                                                                {this.validatorAgency.message('sleeveInventory', agency.Inventories.Sleeve.quantity, 'integer|min:0,num')}
                                                            </div>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group' id="free_dvd_to_client">
                                                        <label htmlFor="name">Free DVD To Client</label>
                                                        <input type="number" name="free_dvd_to_client" className="form-control" min="0" value={agency.free_dvd_to_client} onChange={this.handleAgency} />
                                                        { /* this.validatorAgency.message('free_dvd_to_client', agency.free_dvd_to_client, 'integer') */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6" id="discInventory">
                                                    <div className='form-group'>
                                                        <div className="clearfix">
                                                            <div className="col-md-12 lbl_container">
                                                                <label htmlFor="name">Disc Inventory: </label>
                                                            </div>

                                                            <div className="col-md-12 hasInputIcons">
                                                                {
                                                                    formType === 'edit' &&
                                                                    <div className="inputIcons">
                                                                        <button type='button' className="addIcon orderDetailsAction" onClick={() => this.setEditFormType('Add', 'Disc')}>&nbsp;</button>
                                                                        <button type='button' disabled={agency.Inventories.Disc.quantity > 0 ? false : true} className="adjustIcon orderDetailsAction" onClick={() => this.setEditFormType('Adjust', 'Disc')}>&nbsp;</button>
                                                                    </div>
                                                                }
                                                                <input type="number" readOnly={formType === 'edit' ? true : false} name="quantity" className="form-control" value={agency.Inventories.Disc.quantity} onChange={(event) => this.handleAgencyInv(event, 'Disc')} />
                                                                <span className="text-xxs-rem text-semi-muted">Last year usage: 100</span>
                                                                {this.validatorAgency.message('discInventory', agency.Inventories.Disc.quantity, 'integer|min:0,num')}
                                                            </div>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {
                                            /*
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label htmlFor="name">Invoicing</label>
                                                    <Select
                                                        value={returnLabelWithValue(agency.invoice_style_id, invoice_style_id)}
                                                        onChange={this.handleChangeAgencyDropDown}
                                                        options={invoice_style_id}
                                                        isSearchable={true}
                                                        isMulti={false}
                                                        name='invoice_style_id'
                                                        placeholder={'Select Invoice Style'}
                                                    />
                                                    { this.validatorAgency.message('invoice_style_id', agency.invoice_style_id, 'required') }
                                                </div>
                                            </div>
                                            */
                                        }

                                        {

                                            <div className="col-md-12" >
                                                <div className='form-group' style={{ paddingRight: '6px' }}>
                                                    <label htmlFor="name">Accounts Comments</label>
                                                    <textarea type="text" name="account_comments" style={{ "minHeight": "93px", "marginLeft": "0" }} className="form-control" value={agency.account_comments} onChange={this.handleAgency}></textarea>
                                                    { /* this.validatorAgency.message('account_comments', agency.account_comments, 'required') */}
                                                </div>
                                            </div>

                                        }
                                        <span></span>
                                        <div className="col-md-12" id="invoice_period">
                                            <div className='form-group'>
                                                <div className="clearfix">
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">invoice Period<span className="requiredClass">*</span> </label>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <input type="text" name="invoice_period" className="form-control"
                                                            value={agency.settings.invoice_period.value} onChange={(e) => this.handleChangeAgencySettingInput(e)} />


                                                        {this.validatorAgency.message('invoice_period', agency.settings.invoice_period.value, 'required|min:1,num')}
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <span></span>
                                        {
                                        show_tax_exempt
                                        ?
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className="container-check">Tax Exempt
                                                        <input type="checkbox" name="tax_exempt" className="checkbox" value={agency.tax_exempt} onChange={(e)=>this.handleTaxExampt(agency.tax_exempt)} checked={agency.tax_exempt} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Tax Exampt Check</Tooltip>}>
                                                        <span className="question_mark">?</span>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        :
                                        ''
                                        }
                                    </div>


                                    <div className="col-md-6 pull-right">
                                        <span ></span>
                                        <div className="col-md-12 pl-none pr-none clearfix">
                                            <div className='form-group' id="proshowFileCompanyName">
                                                <div className="clearfix">
                                                    <div className="col-md-12 lbl_container">
                                                        <label htmlFor="name">Mozaix Letter Text</label>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="flexElem">
                                                            <div style={{ width: "100%" }}>
                                                            <CKEditor
                                                                activeClass="p10"
                                                                content={agency.mosaic_letter_text}

                                                                events={{
                                                                    // "blur": this.onBlur,
                                                                    // "afterPaste": this.afterPaste,
                                                                    "change": this.onChange('mosaic_letter_text')
                                                                }}
                                                            />
                                                                {/* <textarea type="text" name="mosaic_letter_text" className="form-control " value={agency.mosaic_letter_text} onChange={this.handleAgency}></textarea> */}
                                                                { /* this.validatorAgency.message('pro show File Company Name', agency.mosaic_letter_text, 'required') */}
                                                            </div>

                                                            <div style={{ width: "60%", marginLeft: "24px" }}>&nbsp;</div>
                                                        </div>
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <div className="flexElem">
                                                    <div style={{ width: "100%" }}>
                                                        <label htmlFor="name">Transmittal letter text</label>
                                                        <CKEditor
                                                            activeClass="p10"
                                                            content={agency.transmittal_letter_text}

                                                            events={{
                                                                // "blur": this.onBlur,
                                                                // "afterPaste": this.afterPaste,
                                                                "change": this.onChange('transmittal_letter_text')
                                                            }}
                                                        />
                                                        {/* <textarea type="text" name="transmittal_letter_text" className="form-control" value={agency.transmittal_letter_text} onChange={this.handleAgency}></textarea> */}
                                                        { /* this.validatorAgency.message('transmittal_letter_text', agency.transmittal_letter_text, 'required') */}
                                                    </div>

                                                    <ul className="giudeTrans" style={{ width: "60%" }}>
                                                        <li >CONTACTNAME
                                                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Group Contact</Tooltip>}>
                                                                <span className="question_mark">?</span>
                                                            </OverlayTrigger>
                                                        </li>

                                                        <li >AGENCYNAME
                                                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Tour Agency</Tooltip>}>
                                                                <span className="question_mark">?</span>
                                                            </OverlayTrigger>
                                                        </li>

                                                        <li >GROUPNAME
                                                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Tour Group</Tooltip>}>
                                                                <span className="question_mark">?</span>
                                                            </OverlayTrigger>
                                                        </li>

                                                        { /*
														<li >OPTIONALGROUPPHOTO
												<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Group Photo Section</Tooltip>}>
																<span className="question_mark">?</span>
															</OverlayTrigger>
														</li>
														*/ }
                                                        <li >PROGRAMDELIVERABLE
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Program Type Materials</Tooltip>}>
                                                                <span className="question_mark">?</span>
                                                            </OverlayTrigger>
                                                        </li>

                                                        <li >UPLOADCUTOFFDATE
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Upload Cut Off Date</Tooltip>}>
                                                                <span className="question_mark">?</span>
                                                            </OverlayTrigger>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !is_ttk &&
                                            <div className="col-md-12 pl-none pr-none">
                                                <div className='form-group' id="proshowFileCompanyName">
                                                    <div className="clearfix">
                                                        <div className="col-md-12 lbl_container">
                                                            <label htmlFor="name">Pro Show File company Name</label>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="flexElem">
                                                                <div style={{ width: "100%" }}>
                                                                    <textarea type="text" name="proshow_file_company_name" className="form-control" value={agency.proshow_file_company_name} onChange={this.handleAgency}></textarea>
                                                                    { /* this.validatorAgency.message('pro show File Company Name', agency.proshow_file_company_name, 'required') */}
                                                                </div>

                                                                <div style={{ width: "60%", marginLeft: "24px" }}>&nbsp;</div>
                                                            </div>
                                                        </div>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <span></span>
                                    </div>

                                    <hr />

                                    <div className="col-md-12 noHeightFormGroup">
                                        <span></span>

                                        <div className="col-md-4">
                                            <div className="row12">
                                                <span></span>
                                                {/* {
                                                    this.agencySettingCheckBox('Disable Allow Map/Messaging', 'allow_mapping_messaging', 'Allow all users to see and use map/messaging feature', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Disable Allow Travelers To Use Map/Messaging', 'allow_traveler_to_use_map', 'Allow travelers to see and use map/messaging feature', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Disable Allow Travelers To See Everyone On The Trip', 'allow_traveler_to_see_all_on_trip', 'Allow travelers to see everyone on the trip', 12)
                                                } */}


                                                {
                                                    this.agencySettingCheckBox('Do Not Allow Anyone To Use Map', 'allow_anyone_to_use_mapping', 'Disable all users to see and use map feature', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Do Not Allow Anyone To Use Messaging', 'allow_anyone_to_use_messaging', 'Disable all users to use messaging feature', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Do Not Allow Travelers To See Everyone On The Trip', 'allow_travelers_to_use_mapping', 'Disable travelers to see and use map feature', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Do Not Allow Travelers To See Everyone On The Trip', 'allow_travelers_to_use_messaging', 'Disable travelers to use messaging feature', 12)
                                                }


                                                {
                                                    this.agencySettingCheckBox('Disable Social Media Sharing', 'disable_social_media_sharing', 'If this checkbox is true then social media sharing is disable', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBoxReverse('Disable Auto Approve for Gallery Viewing', 'auto_approve_photos', 'Automatically Approve Uploaded Photos for Gallery Viewing. All companies are automatically set to approve posting', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Disable Auto Approve For Upload', 'disable_auto_approve', 'All companies are automatically set to auto approve for upload', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Do Not Send SMS', 'do_not_send_sms', 'All companies are automatically set to auto approve for upload', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Enable Traveler-to-Traveler Messaging', 'is_allow_one_to_one_chat', 'All companies automatically Restrict Travelers From Messaging Each Other', 12)
                                                }
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row12">
                                                <span></span>
                                                {
                                                    this.agencySettingCheckBox('Do Not Call Customers', 'do_not_call_customer', 'If checked it will display in grey color in contact listing page', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Disable Minimum Price', 'disable_minimum_price', 'Check for YES', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Secondary Address For T/O Copies', 'second_address_to_copies', 'Check for YES', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Collect Tax', 'collect_tax', 'Collect tax on order', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Allow PreAwareness Flyer Generation', 'allow_preawareness_flyer_generation', 'Allow Agency to generate PreAwareness Flyers', 12)
                                                }
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row12">
                                                <span></span>
                                                {
                                                    this.agencySettingCheckBox('Hosts Images', 'hosts_images', 'If checked the images for this agency has been hosted on the given website url.', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Generate Photo Zips', 'generate_photo_zips', 'Generate Photo .Zips for this T/O (these will be downloadable when T/O logs into GTV.com)', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Keep High Resolution Photos', 'keep_high_resolution', 'If checked this will this significantly increase the amount of storage used by photos, this should only be set for very special high-volume companies', 12)
                                                }

                                                {
                                                    this.agencySettingCheckBox('Show Logo on GTV.com', 'show_logo_file_on_front_end', 'If checked logo will appear on GTV.com client page', 12)
                                                }
                                                {
                                                    this.agencySettingCheckBox('Free Flash Drive to Client', 'free_fash_drive_to_client', 'If checked logo will not appear on GTV.com client page', 12)
                                                }
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } />
                    </div>
                    <div className="text-center">
                        {
                            (
                                (
                                    (formType === 'add') && hasPermission('agency-create')
                                ) ||
                                (
                                    (formType === 'edit') && hasPermission('agency-edit')
                                )
                            ) &&
                            <Button type='submit' form="agency-form" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm" >Save</Button>
                        }
                        {
                            this.props.location.hash ?
                                <Link to={`/Admin/${this.props.location.hash.replace('#', '')}/order-edit`} className="backButton pt-sm no_radius pb-sm primary btn btn-lg btn-info ml-sm mt-sm">Cancel</Link>
                                :
                                <Link to="/Admin/agencies" className="backButton pt-sm no_radius pb-sm primary btn btn-lg btn-info ml-sm mt-sm">Cancel</Link>
                        }
                    </div>
                </form>
                {/*Edit Inventory Modal start*/}
                {agency && formType === 'edit' && currentInv in agency.Inventories &&
                    <Modal backdrop={'static'} show={this.state.editAgencyInvModal} onHide={closeModal.bind(this, "editAgencyInvModal")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                            <Modal.Title id='ModalHeader' className="headerTitle">{editInvFormType} Inventory</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <Card bsClass={["innerCard mb-none"]} content={
                                        <InventoryForm
                                            inventoryPro={agency.Inventories[currentInv]}
                                            closeModel={closeModal.bind(this, "editAgencyInvModal")}
                                            formTypePro="edit"
                                            componentPro="Inventory"
                                            handleInventorySubmit={this.handleInventorySubmit}
                                            options={
                                                {
                                                    'items': items,
                                                    'agencies': agenciesOption,
                                                }
                                            }
                                            inventoryType={editInvFormType}
                                            defaultAgency={false}
                                        />
                                    } />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
                {/*Edit Inventory Modal End*/}
            </div>
        );
    };
}

const mapDispatchToProps = dispatch => {
    return ({
        getAgencyTypeCall: () => { dispatch(getAgencyType()) },
        getPVSalesRepCall: () => { dispatch(getSalesRep()) },
        getCountriesCall: () => { dispatch(getCountries()) },
        // getInvoicePeriodCall: () => { dispatch(getInvoicePeriod()) },
        getProgramTypeCall: () => { dispatch(getProgramType()) },
        getSettingValueCall: (key, stateName) => { dispatch(getSettingValue(key, stateName)) },
        storeAgency: (agencyData) => { dispatch(storeAgency(agencyData)) },
        updateAgency: (agencyData, agency_id) => { return dispatch(editAgency(agencyData, agency_id)) },
        clearSettingsCall: () => { dispatch(clearSettings()) },
        getBrandsCall: () => { dispatch(getBrands()) },
        getItemsCall: (type) => { dispatch(getItems(type)) },
        getAgenciesCall: () => { dispatch(getAllAgencies()) },
        getInvoicePeriodCall: () => { return dispatch(getInvoicingSettings()) },
        updateAgencyInventoryCall: (data, id, type) => { return dispatch(updateAgencyInventory(data, id, type)) },
        deleteAgencyCall: (id) => { return dispatch(deleteAgency(id)) },
		getAgencyCall: (id) => { dispatch(getAgency(id)) },
    });
};
function mapStateToProps(state) {
    const { loadingAgency, agenciesOption, updatedInv } = state.agenciesReducer;
    const { brands } = state.accountReducer;
    const { loadingSettings, countries, agency_type_id, salesRep, programType } = state.settingsReducer;
    const { loadingItem, items } = state.itemReducer;
    return {
        loadingAgency, agenciesOption, updatedInv,
        brands,
        loadingSettings, countries, agency_type_id, salesRep, programType,
        loadingItem, items,
    };
}

const AgencyForm = connect(mapStateToProps, mapDispatchToProps)(AgencyFormPage);

export default withRouter(AgencyForm);
