import React, { Component, Fragment } from 'react';
import { orderService } from '../../../services/order';
import { Col, Row } from 'react-bootstrap';
import { toast } from "react-toastify";

class AiModalTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aiModels: [],
            aiModelsClasses: [],
            aiModelsSubClasses: [],
            tabs: {
                showModels: true,
                showModelClasses: false,
                showModelSubClasses: false,
                showModelTrainingData: false,
                tiltInput: false,
                showTextArea: false,
                similarToPositions: false,
            },
            selectedModel: null,
            selectedModelsClasses: null,
            selectedModelsSubClasses: null,
            classes: [],
            // tiltDirection: '',
            textValue: '',
            teaxtAreaHeading: '',
            correct_image: '',
            positionHeading: '',
            modalClassesContent: '',
            subClassOther: '',
            BadGesture: '',
        }
    }

    componentDidMount() {
        let key = 'for_video';
        if (this.props.modalName && this.props.modalName === 'Editor'){ 
            key = 'for_omitted';
        }
        orderService.getAiModels({key: key}).then((response) => {
            if (response) {
                this.setState({
                    aiModels: response.data
                })
            }
        })
    }
    
    componentDidUpdate(prevProps, prevState) { 
        if (this.props.image && this.props.image !== prevProps.image) {
            console.log("Image not same", this.props.image.id);
            this.initialState();
            this.props.trainingDataSubmitted(false);
            
        }
    }
    
    initialState = () => {
        this.setState({
            tabs: {
                ...this.state.tabs, 
                showModels: true,
                showModelClasses: false,
                showModelSubClasses: false,
                showModelTrainingData: false,
                tiltInput: false,
                showTextArea: false,
                similarToPositions: false,
            },
            selectedModel: null,
            selectedModelsClasses: null,
            selectedModelsSubClasses: null,
            classes: [],
            textValue: '',
            teaxtAreaHeading: '',
            correct_image: '',
            positionHeading: '',
        })
    }

    textAreaChangeHandler = (e) => {
		const { value } = e.target;
        this.setState({ textValue: value})
        if (this.state.selectedModelsClasses && !this.state.selectedModelsSubClasses) {

            const newId = this.state.selectedModelsClasses.id;
                const newValue = value;
    
                // Call the function to update the state
                this.updateClasses(newId, newValue);
            
        } else if (this.state.selectedModelsClasses && this.state.selectedModelsSubClasses) {
            const newId = this.state.selectedModelsSubClasses.id;
                const newValue = value;
    
                // Call the function to update the state
                this.updateClasses(newId, newValue);
        }
    }

    clearState = () => {
        
        this.setState({
            selectedModelsSubClasses: null,
            // tiltDirection: '',
            textValue: '',
            classes: [],
            tabs: {
                ...this.state.tabs, 
                showModels: true,
                showModelSubClasses: false,
                tiltInput: false,
                showTextArea: false,
                similarToPositions: false
            }
        })
    }

    updateClasses = (newId, newValue) => {
        this.setState((prevState) => {
            // Add a safety check to ensure classes is an array
            const updatedClasses = (prevState.classes || []).map((classItem) => {
                if (classItem.id === newId) {
                    // Update the value if the id already exists
                    return { ...classItem, value: newValue };
                }
                return classItem;
            });

            // Check if the id was already in the array
            const idExists = (prevState.classes || []).some(classItem => classItem.id === newId);

            // If id does not exist, push the new id-value pair
            if (!idExists) {
                updatedClasses.push({ id: newId, value: newValue });
            }

            return { classes: updatedClasses };
        });
    }

    handleRadioChange = (record, key) => {
        const { slug, is_description, title } = record;
        let data = {
            parent_id: null
        }
        if (key === 'ai_model' && !is_description) {
            orderService.getAiModelsClasses(record.id, data).then((response) => {
                if (response) {
                    this.clearState();
                    this.props.trainingDataSubmitted(true);
                    this.setState({
                        aiModelsClasses: response.data,
                        selectedModelsClasses: null,
                        selectedModel: record,
                        teaxtAreaHeading: record.title,
                        subClassOther: '',
                        modalClassesContent: '',
                        tabs: {
                            ...this.state.tabs, 
                            showModels: true,
                            showModelClasses: true,
                            showModelSubClasses: false,
                            showModelTrainingData: false,
                            tiltInput: false,
                            similarToPositions: false
                        }
                    })
                    console.log("results", response);
                    
                    if (response.data[0].slug === "too_dark") {
                        this.setState({ selectedModelsClasses: response.data[0]})
                    }
                }
            })
        } else if (key === 'ai_model' && is_description) {
            this.clearState();
            if (slug.includes('tilt')) {

                this.setState({
                    selectedModel: record,
                    teaxtAreaHeading: record.title,
                    selectedModelsClasses: null,
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: false,
                        showModelClasses: false,
                        showModelSubClasses: false,
                        tiltInput: true
                    }
                })
            } else {
                this.setState({
                    selectedModel: record,
                    teaxtAreaHeading: record.title,
                    selectedModelsClasses: null,
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: true,
                        showModelClasses: false,
                        showModelSubClasses: false,
                        tiltInput: false,
                        similarToPositions: false
                    }
                })

            }
        }
    }
    getColumnRadio = (record, key) => {
        const { slug, id, title } = record;
        const { selectedModel, selectedModelsClasses, selectedModelsSubClasses } = this.state;
        
        let checked = null;
        if (key === 'ai_model') {
            checked = selectedModel && selectedModel.id
        } else if (key === 'ai_model_classes') {
            checked = selectedModelsClasses && selectedModelsClasses.id
        } else {
            checked = selectedModelsSubClasses && selectedModelsSubClasses.id
        }
        
    
        return (
            <Col xs={12} md={6} key={id}>
                <label key={id} className="container-radio checkBG font14 justifyEnd w-100 alignTop" style={{marginBottom: 5}}>
                    <span style={{ textTransform: 'capitalize' }}>
                        {`${title}`}
                    </span>
                    <input
                        value={JSON.stringify(record)}
                        type='radio'
                        name={key} // Same name for all radios
                        id={id}
                        className="radio font14"
                        checked={checked === id}
                        onChange={() => this.handleRadioChange(record, key)}
                    />
                    <span className="checkmark" />
                </label>
            </Col>
        );
    };
    handleCheckboxChange = (event, record, key) => {
        console.log("is_description", record.is_description);
        console.log("key", key);
        
        this.setState({ selectedModelsClasses: record, teaxtAreaHeading: record.title })
        const { classes, textValue } = this.state;
        const { slug, is_description, title } = record;
        const checked = event.target.checked;
        console.log('title', title);
        
        let data = {
            parent_id: null
        }
        if (key === 'ai_model_classes') {
            if (slug && slug.includes('content') && checked) {
                data = {
                    parent_id: record.id
                }
                orderService.getAiModelsClasses(this.state.selectedModel.id, data).then((response) => {
                    if (response) {
                        this.setState({
                            aiModelsSubClasses: response.data,
                            tabs: {
                                ...this.state.tabs, 
                                showModels: true,
                                showModelClasses: true,
                                showModelSubClasses: true,
                            },
                            modalClassesContent: record.title
                        })
                    }
                })
            } else if (slug && slug.includes('need_straighten')) {
    
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: false,
                        showModelSubClasses: false,
                        tiltInput: true
                    }
                })
            } else if (slug && slug === 'similer_to_other' ) {
                this.setState({
                    positionHeading: 'Similer To Position #',
                    tabs: {
                        ...this.state.tabs,
                        similarToPositions: true,
                        showTextArea: true
                    }
                })
            } else if (title.includes('Bad Gesture') && checked) {
                console.log("Bad Gesture && checked");
                
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: true
                    },
                    modalClassesContent: record.title
                })
            } else if (is_description && checked) {
                console.log("is_description && checked");
                
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: true
                    }
                })
            } else if (is_description && !checked) {
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: false
                    },
                    aiModelsSubClasses: [],
                    selectedModelsSubClasses: null,
                    modalClassesContent: ''
                })
            }
        } else if (key === 'ai_model_sub_classes') {
            
            this.setState({ 
                selectedModelsSubClasses: record,
                teaxtAreaHeading: record.title
            })
            if (title.includes('Other') && checked) {
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: true
                    },
                    subClassOther: record.title
                })
            } else if (title.includes('Other') && !checked) {
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        showTextArea: false
                    },
                    subClassOther: ''
                })
            }
        }

        // Check if the class is already selected
        const classIndex = classes.findIndex((classItem) => classItem.id === record.id);

        if (classIndex === -1) {
            // If not selected, add the new class with the current textValue
            if (textValue && record.is_description ) {
                this.setState((prevState) => ({
                    classes: [...prevState.classes, { id: record.id, value: textValue }],
                }));
            } else {
                this.setState((prevState) => ({
                    classes: [...prevState.classes, { id: record.id, value: null }],
                }));
            }
        } else {
            // If selected, remove it
            this.setState((prevState) => ({
                classes: prevState.classes.filter((classItem) => classItem.id !== record.id),
            }));
        }
    };

    getColumnCheckbox = (record, key) => {
        const isChecked = this.state.classes.some((classItem) => classItem.id === record.id);

        return (
            <Col xs={12} md={6} key={record.id}>
                <label className="container-radio checkBG font14 justifyEnd w-100 alignTop" style={{ marginBottom: 5 }}>
                    <span style={{ textTransform: 'capitalize' }}>{`${record.title}`}</span>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => this.handleCheckboxChange(e, record, key)}
                    />
                    <span className="checkmark" />
                </label>
            </Col>
        );
    };

    saveModelHandler = () => {
        const {selectedModel, selectedModelsClasses, classes, textValue, correct_image} = this.state;
        const { image, producedImagesData } = this.props;
        let correct_image_id = null;
        let wrong_image_id = null;
        if (correct_image && correct_image > 0) {
            console.log("condition true", correct_image, image.producer_sort_order -1);
            
            const c_image = producedImagesData.filter(item => {
                return Number(item.producer_sort_order) === Number(correct_image) ;
            });
            console.log("Correct image", c_image);
            
            const w_image = producedImagesData.filter(item => {
                return Number(item.producer_sort_order) === Number(image.producer_sort_order) - 1;
            });

            console.log("Wrong image", w_image);
            if (c_image && w_image) {
                correct_image_id = c_image[0] ? c_image[0].id : null;
                wrong_image_id = w_image[0] ? w_image[0].id : null;
            }
        }

        const data = {
            image_id: image.id,
            correct_image_id: correct_image_id,
            wrong_image_id: wrong_image_id,
            order_id: image.model_id,
            file_path: image.file_path + image.image_versions.original_file_name + '.' + image.image_versions.extension,
            model_id: selectedModel.id,
            classes: classes ? classes : null,
            // sub_class_id: selectedModelsSubClasses ? selectedModelsSubClasses.id : null,
            value: textValue ? textValue : null
        }
        orderService.storeModelTrainingData(data).then((response) => {
            console.log("Saving model response", response);
            if (response.message) {
                this.handleSuccess(response.message);
            }
        })
        
        console.log("Saving model configuration", data);
    }

    handleSuccess = (message) => {
        toast.success(message, {
          position: "bottom-right",
          autoClose: 10000, // Auto close after 10 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light", // You can also use "dark"
        });
    }

    render() {
        const { aiModels, tabs, aiModelsClasses, textValue, selectedModel, teaxtAreaHeading, selectedModelsClasses, aiModelsSubClasses, positionHeading, modalClassesContent, subClassOther } = this.state;
        return (
            <React.Fragment>
                <h5 className='mb-md'>Data Collection For Ai Model Training</h5>
                <Row className="mb-2">
                    {
                        aiModels && aiModels.length > 0 
                        ?
                        <Fragment>
                            {
                                aiModels.map(el => this.getColumnRadio(el, 'ai_model'))
                            }
                        </Fragment>
                        : 
                        null
                    }
                    
                    {
                        aiModelsClasses && aiModelsClasses.length > 0 && tabs.showModelClasses 
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h5 className='mt-sm px15 textCapNone'>{selectedModel.title}</h5>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            {
                                aiModelsClasses.map(el => this.getColumnCheckbox(el, 'ai_model_classes'))
                            } 
                            </Row>
                        </Fragment>
                        : 
                        null
                    }     
                    {
                        aiModelsSubClasses && aiModelsSubClasses.length > 0 && tabs.showModelSubClasses 
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h5 className='mt-sm px15 textCapNone'>{modalClassesContent ? modalClassesContent : selectedModelsClasses.title}</h5>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            {
                                aiModelsSubClasses.map(el => this.getColumnCheckbox(el, 'ai_model_sub_classes'))
                            } 
                            </Row>
                        </Fragment>
                        : 
                        null
                    }     


                    {/* // input code*/}

                    {
                        tabs.tiltInput 
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h5 className='mt-sm px15 textCapNone'>{subClassOther ? subClassOther : teaxtAreaHeading}</h5>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            
                            {
                                tabs.tiltInput &&
                                <Col xs={12} md={12} className='mt-md'>
                                    <input 
                                        placeholder='Tilt Direction' 
                                        type="number" 
                                        name="tilt_direction" 
                                        // min="0" 
                                        className='form-control' 
                                        style={{width: 'auto'}} 
                                        value={this.state.textValue}
                                        onChange={this.textAreaChangeHandler} 
                                        // onChange={(e) => this.setState({ textValue: e.target.value })} />
                                    />
                                </Col>
                                
                            }
                            </Row>
                        </Fragment>
                        : 
                        null
                    }    
                    {
                        tabs.similarToPositions
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h5 className='mt-sm px15 textCapNone'>{positionHeading}</h5>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            
                            {
                                tabs.similarToPositions &&
                                <Col xs={12} md={12} className='mt-md'>
                                    <input 
                                        placeholder='Position #' 
                                        type="number" 
                                        name="tilt_direction" 
                                        min="0" 
                                        pattern='\d*'
                                        className='form-control' 
                                        style={{width: 'auto'}} 
                                        value={this.state.correct_image}
                                        onChange={(e) => this.setState({ correct_image: e.target.value })}
                                    />
                                </Col>
                                
                            }
                            </Row>
                        </Fragment>
                        : 
                        null
                    }    




                    {/* // Text area code*/}

                    {
                        tabs.showTextArea 
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            <h5 className='mt-sm px15 textCapNone'>{subClassOther ? subClassOther : modalClassesContent ? modalClassesContent : teaxtAreaHeading}</h5>
                            <Row className='py10 mt-xs borderDasshedTop mx-0'>
                            
                            {
                                tabs.showTextArea &&
                                <Col xs={12} md={12} className='mt-md'>
                                {/* <h3>Explanation</h3> */}
                                <textarea
                                    value={textValue}
                                    name="textValue"
                                    onChange={this.textAreaChangeHandler}
                                    className='form-control font14'
                                    placeholder={`Explain why ${subClassOther ? subClassOther : modalClassesContent ? modalClassesContent : teaxtAreaHeading}`}
                                    id="textValue"
                                ></textarea>
                                </Col>
                            }
                            </Row>
                        </Fragment>
                        : 
                        null
                    }                  
                    {
                        selectedModelsClasses || tabs.showTextArea || tabs.tiltInput
                        ?
                        <Fragment> 
                            <div style={{clear:'both'}} />
                            {/* <h5 className='mt-sm px15 textCapNone'>{teaxtAreaHeading}</h5> */}
                            <Row className='py10 mt-xs  mx-0'>
                            
                            {
                                <Col xs={12} md={12} className='mt-md text-center'>
                                    <button 
                                        name='add' 
                                        type="button" 
                                        className='backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm' 
                                        onClick={this.saveModelHandler} 
                                    >Save</button>
                                                                
                                </Col>
                            }
                            </Row>
                        </Fragment>
                        : 
                        null
                    }                  
                </Row>
            </React.Fragment>
        );
    }
}


export default AiModalTraining;