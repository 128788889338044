import React from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { orderImagesWR, replaceImage } from 'redux/actions/order-actions';
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import DownloadFile from 'components/ThemeComponents/downloadFile';
import { returnTimeStamp, dateTimeWithAMPM, closeModal } from 'helper/helperFunctions';
import MyImage from 'components/Upload/MyImage';
import { orderService } from 'services/order';
import { rotateImage, deleteImage } from 'redux/actions/imageActions';
import { showLoader } from 'redux/actions/settings-actions';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import ReactDataToCSV from 'components/ThemeComponents/reactDataToCSV';
import Pagination from 'components/ThemeComponents/Pagination';
import { confirmAlert } from 'react-confirm-alert';
import OpenModalButton from 'components/ThemeComponents/openModelButton';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
var moment = require("moment");
var Modal = require('react-bootstrap-modal');

class ImagesListPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageSizeTotal: 0,
			sorted: '',
			filtered: '',
			imagePage: 0,
			replaceImageModal: false,
			replaceImage: { value: {} },
			allowedFormatsStringMime: 'image/jpeg',
			allowedFormatsStringExt: [".jpg"],
		};
		// this.imagesPaginationData  	= this.imagesPaginationData.bind(this);
		this.filterSearchHandle = this.filterSearchHandle.bind(this);
		this.imagesPaginationReload = this.imagesPaginationReload.bind(this);

	};
	imagesPaginationReload() {
		this.pagination.dataCall();
		// this.imagesPaginationData(0, '', '', 100);
	}
	// imagesPaginationData(page, filter, sort, pageSize){
	//     this.setState({imagePage:page,filtered:filter, sorted:sort, pageSizeTotal:pageSize}, function(){
	//     	this.props.orderImagesCall(this.props.orderId, this.props.versionType, this.props.tabName, page, filter, sort, pageSize);
	//     });
	//  	}
	filterSearchHandle(e) {
		this.setState({
			'filtered': e.target.value
		});
	}
	componentDidMount() {
		this.props.provideCtrlListing({
			imagesPaginationReload: () => this.imagesPaginationReload()
		});
	}
	componentWillUnmount() {
		this.props.provideCtrlListing(null);
	}
	paginationCall = (data) => {
		// return this.props.orderImagesCall(this.props.orderId, this.props.versionType, this.props.tabName, data.page, this.state.filtered, data.sort, data.pageSize);

		return orderService.orderImages(this.props.orderId, this.props.versionType, this.props.tabName, data.page, this.state.filtered, data.sort, data.pageSize).then(response => {
			return response;
		});
	}


	rotateImage = (id, direction, version_type) => {
		this.props.rotateImageCall(id, direction, version_type).then(response => {
			this.pagination.dataCall();
		});
	}

	confirmImageDelete = (id, version_type) => {
		confirmAlert({
			customUI: ({ onClose }) => <ConfirmAlertForDelete typeName='Image' onClosePro={onClose} deleteType={() => { this.deleteImage(id, version_type) }} />
		})
	}


	deleteImage = (id, version_type) => {
		this.props.deleteImageCall(id, version_type).then(
			res => {
				this.pagination.dataCall();
			}
		);
	}

	setResourceFile = (key, value) => {
		this.setState((prevState) => {
			return {
				[key]: { ...this.state[key], value: value }
			}
		}, () => {
			console.log('state', key);
		});

	}
	removeFile = (key, index) => {
		this.setState({
			[key]: { value: {} },
		});
	}

	saveImages = () => {
		// this.props.showLoaderCall(false);

		const replaceImage = this.state.replaceImage;
		const fd = new FormData();
		fd.append('value', replaceImage.value);
		fd.append('imageId', replaceImage.imageId);
		fd.append('orderId', this.props.orderId);
		fd.append('folder', 'working');
		// fd.append('firstName', this.state.firstName);
		// fd.append('lastName', this.state.lastName);

		// console.log('ffff dddd', this.state.replaceImage);

		this.props.replaceImageCall(fd).then(
			response => {
				this.pagination.dataCall();
				// this.props.showLoaderCall(true);
				this.setState({ replaceImage: { value: {} }, replaceImageModal: false });
			},
			error => {
				console.log('error');
			}
		)
	}

	toggleReplaceImageModal = (name, imageId) => {
		this.setState({ [name]: !this.state[name], replaceImage: { ...this.state.replaceImage, imageId: imageId } });
	}
	render() {
		const { replaceImageModal, replaceImage, allowedFormatsStringMime, allowedFormatsStringExt } = this.state;
		// const {
		// 	// imagesData, pages,
		// 	// loadingOrder
		// }  = this.props;

		const columns = [{
			Header: "Images Listing",
			headerClassName: 'headerPagination',
			columns: [
				{
					Header: "Name",
					accessor: "image_versions.file_label",
					className: "action-center",
					excExp: true,
					Cell: ({ row }) => (
                        <div >
                            {
                            row._original.producer_sort_order_label 
                            ? 
                                row._original.producer_sort_order_label 
                            : 
                                row._original.image_versions.file_label 
                                ?
                                    row._original.image_versions.file_label
                                :
                                    row._original.id
                            }
                        </div>
                    ),
				},
				{
					Header: "Uploader",
					accessor: "uploader_name",
					className: "action-center",
					excExp: true,
					show: false,
				},
				{
					Header: "Uploaded From",
					accessor: "is_web_image",
					className: "action-center",
					excExp: true,
					show: false,
				},
				{
					Header: "Sample",
					accessor: "file_path",
					className: "action-center",
					width: 170,
					Cell: ({ row }) => (
						<div  >
							<MyImage alt={row._original.file_title} key={row._original.id} src={row._original.resized_image_path.replace('SIZE', 'thumb_mid') + '?rand=' + returnTimeStamp(row._original.image_versions.updated_at)} height="50px" width="50px" />
							{
								(this.props.tabName === 'Video Producer' && Number(this.props.order_details.settings.finalize_by_pm.value) === 0) &&
								<React.Fragment>
									<div className='mt-xs'>
										<OpenModalButton
											// openModal={openModal.bind(this, "replaceImageModal")}
											openModal={() => this.toggleReplaceImageModal("replaceImageModal", row._original.id)}
											classButton={['p-xs mt-sm mr-xs']}
											buttonName=""
											tooltipText="Replace Image"
											classIcon={['fa', 'fa-exchange', 'fa-sm']}
										/>
										<button className='mr-xs p-xs' onClick={() => { this.confirmImageDelete(row._original.id, 'working') }}  >
											<i className='fa fa-trash fa-sm'></i>
										</button>
										<button onClick={() => this.rotateImage(row._original.id, 'left', 'working')} className='mr-xs p-xs'>
											<i style={{ cursor: 'pointer' }} className='fa fa-undo fa-sm'></i>
										</button>
										<button onClick={() => this.rotateImage(row._original.id, 'left', 'working')} className='p-xs'>
											<i style={{ cursor: 'pointer' }} className='fa fa-repeat fa-sm'></i>
										</button>
									</div>


								</React.Fragment>
							}

						</div>
					),
					sortable: false,
					excExp: false,
				},
				{
					Header: "Created On",
					accessor: "created_at",
					className: "action-center",
					Cell: ({ value }) => (
						<div>
							{dateTimeWithAMPM(value)}
						</div>
					),
					excExp: true,
				},
				{
					Header: "Last Update",
					accessor: "updated_at",
					className: "action-center",
					Cell: ({ value }) => (
						<div>
							{dateTimeWithAMPM(value)}
						</div>
					),
					excExp: false,
				},
				{
					Header: "File Size",
					accessor: "image_versions.file_size",
					className: "action-center",
					Cell: ({ value }) => (
						<div>
							{ value + ' KB'}
						</div>
					),
					excExp: true,
				},
				{
					Header: "Status",
					accessor: "approved_status",
					className: "action-center",
                    Cell: ({ value }) => (
						<div>
							{ value ? 'Approved' : 'Not Approved' }
						</div>
					),
					sortable: false,
				},
				{
					Header: "Uploader Name",
					accessor: "uploader_name",
					className: "action-center",
					sortable: false,
				},
				{
					Header: "Uploaded by",
					accessor: "is_web_image",
					className: "action-center",
					sortable: false,
				},
				{
					Header: "Download",
					accessor: "file_path",
					className: "action-center",
					Cell: ({ row }) => (
						<div>
							<DownloadFile
								title="Image"
								multiple={false}
								files={{
									'file_title': row._original.image_versions.file_label,
									'file_path': row._original.image_versions.file_path,
									// 'file_path':row._original.resized_image_path.replace('SIZE', 'resize')+'?rand='+returnTimeStamp(row._original.image_versions.updated_at)
								}}
							/>

						</div>
					),
					sortable: false,
					excExp: false,
				},
			]
		}];
		return (
			<Card
				ctTableResponsive
				content={
					<div>
						<div className="container-fluid p-none">
							<Row>
								<Pagination
									ref={(p) => this.pagination = p}
									columns={columns}
									getDataCall={this.paginationCall}
									filterView={false}
									pageSize={100}
									upperContent={
										<Row>
											<Col md={8} sm={8} xs={12}>
												{this.props.extraHtml}
											</Col>

                                            {!(this.props.tabName === 'Video Producer' || this.props.tabName === 'Quality Control')
                                                ?
                                                    <React.Fragment>
                                                        <Col md={4} sm={6} xs={12} >

                                                            <FormInputs
                                                                ncols={["col-md-12"]}
                                                                onChange={this.filterSearchHandle}
                                                                proprieties={[
                                                                    {
                                                                        type: "text",
                                                                        bsClass: "form-control",
                                                                        placeholder: "Search Image",
                                                                        onChange: this.filterSearchHandle,
                                                                        name: "filter"
                                                                    }
                                                                ]}
                                                            />
                                                        </Col>
                                                    </React.Fragment>
                                                :
                                                ''
                                            }
											{
												this.props.tabName === 'Production-Manager' &&
												<Col md={12} className="mt-sm downloadCsv">
													<ReactDataToCSV
														className="downloadbtn"
														fileName={"OrderImagesData_" + moment().format('MMM/D/YYYY')}
														currentRecordsRef={this.reactTable}
														columnsData={columns[0].columns}
													/>
												</Col>
											}
										</Row>
									}
									downloadData={false}
								// lowerContent = {null}
								// filterPlaceHolder = {'Images'}
								//getRowProps = { this.getRowProps }
								/>
								{
									/*
										<ReactTable
											  ref={(r) => this.reactTable = r}
											noDataText= 'No Images found'
											data={imagesData}
											pages={pages}
											loading = {loadingOrder}
											columns={columns}
											filtered={filtered}
											defaultPageSize={100}
											minRows = {5}
											className="-striped listing"
											pageData = {this.imagesPaginationData}
											manual
											onFetchData={(state, instance) => {
												var sort = state.sorted.length === 0 ? '' : state.sorted[0].id+',desc:'+state.sorted[0].desc;
												state.pageData(state.page+1, state.filtered, sort, state.pageSize);
											}}
										/>
									*/
								}

							</Row>
						</div>

						<Modal backdrop={'static'} show={replaceImageModal} onHide={closeModal.bind(this, "replaceImageModal")} aria-labelledby="ModalHeader" className="editOrderDetail" >
							<Modal.Header closeButton>
								<Modal.Title id='ModalHeader' className="headerTitle">Replace Image</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<React.Fragment>
									<span className="customUploader"></span>

									{
										Object.keys(replaceImage.value).length > 0 &&
										<button type="button" className="orderDetailsAction finalizeIcon" onClick={this.saveImages}>Save</button>
									}
									<FileUpload
										setResourceFile={this.setResourceFile}
										multiple={false}
										allowedFormatsStringMimePro={'image/jpeg, image/png'}
										allowedFormatsStringExtPro={'jpg, png'}
										title={'Replace Image'}
										type={'replaceImage'}
									/>

									<ImagePreview
										filesToPreview={
											Object.keys(replaceImage.value).length > 0
											&&
											{
												preview: replaceImage.value.preview,
												name: replaceImage.value.name,
												type: replaceImage.value.type,
												id: replaceImage.value.id,
											}
										}
										removeFile={Object.keys(replaceImage.value).length > 0 ? this.removeFile : null}
										type={'replaceImage'}
										showName={false}
									/>
								</React.Fragment>
							</Modal.Body>
						</Modal>
					</div>
				} />
		);
	};
}

function mapStateToProps(state) {
	const {
		loadingOrder,
		order_details,
	} = state.ordersReducer;
	return {
		loadingOrder,
		order_details
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		orderImagesCall: (orderId, version_type, tabName, page, filter, sort, pageSize) => { return dispatch(orderImagesWR(orderId, version_type, tabName, page, filter, sort, pageSize)) },
		rotateImageCall: (imageId, direction, version_type) => { return dispatch(rotateImage(imageId, direction, version_type)) },
		deleteImageCall: (id, version_type) => { return dispatch(deleteImage(id, version_type)) },
		showLoaderCall: (status) => { dispatch(showLoader(status)) },
		replaceImageCall: (imagesData) => { return dispatch(replaceImage(imagesData)) },
	});
};

const ImagesList = connect(mapStateToProps, mapDispatchToProps)(ImagesListPage);
export default ImagesList;
